import axios from 'axios'

export default {
  async get(scheduleId) {
    const resp = await axios.get('/api/timetables', { params: { schedule_id: scheduleId } })
    return resp.data
  },
  async getCurrent(scheduleId) {
    const resp = await axios.get('/api/timetables/current', { params: { schedule_id: scheduleId } })
    return resp.data
  },
  async getPrevious(scheduleId) {
    const resp = await axios.get('/api/timetables/previous', {
      params: { schedule_id: scheduleId },
    })
    return resp.data
  },
  async create(device_id, scheduleId, body) {
    await axios.post('/api/timetables', { schedule_id: scheduleId, device_id, ...body })
  },
  async update(device_id, id, body) {
    await axios.put(`/api/timetables/${id}`, { device_id, ...body })
  },
  async markAsEnd(device_id, id) {
    await axios.put(`/api/timetables/${id}`, { device_id, is_end: true })
  },
  async markAsNotEnd(device_id, id) {
    await axios.put(`/api/timetables/${id}`, { device_id, is_end: false })
  },
  async remove(device_id, id) {
    await axios.post(`/api/timetables/delete/${id}`, { device_id })
  },
  async rescue(device_id, scheduleId) {
    await axios.post(`/api/timetables/rescue`, { device_id, schedule_id: scheduleId })
  },
  async toggleCheckbox(device_id, timetableId, todoItemId, checked) {
    await axios.post(`/api/timetables/todos/completed`, {
      device_id,
      timetable_id: timetableId,
      todo_item_id: todoItemId,
      checked,
    })
  },
  async sort(device_id, scheduleId, id, to) {
    await axios.post('/api/timetables/sort', {
      group_id_value: scheduleId,
      id_value: id,
      to,
      device_id,
    })
  },
}
