<template>
  <div
    v-show="wrapper"
    class="ui-fullscreen-modal__wrapper fixed left-0 right-0 bottom-0 flex items-center justify-center z-10"
  >
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      @before-enter="wrapper = true"
      @after-leave="wrapper = false"
    >
      <div
        v-if="computedValue"
        class="ui-fullscreem-modal__overlay fixed inset-0 transition-opacity"
      >
        <div class="absolute inset-0 bg-black opacity-75" />
      </div>
    </transition>

    <transition
      enter-active-class="ease-out-quint transform duration-300"
      enter-class="opacity-25 translate-y-full"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="ease-out-quint transform duration-300"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-25 translate-y-full"
    >
      <div
        v-if="computedValue"
        class="ui-fullscreen-modal__main bg-level-2 rounded-t-2xl shadow-xl transform transition-all w-full z-20 h-full flex flex-col"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="pt-4 px-4 flex items-center justify-end">
          <button
            type="button"
            class="bg-level-3 focus:outline-none text-sub text-sm rounded-full text-center align-middle w-8 h-8 shadow-xl"
            @click="computedValue = false"
          >
            <font-awesome-icon :icon="['fas', 'times']" />
          </button>
        </div>
        <div class="ui-fullscreen-modal__content pt-0 px-4 overflow-x-hidden overflow-y-auto flex-1">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UiFullscreenModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrapper: this.value,
      newValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.newValue = value;
        if (value) {
          document.documentElement.classList.add('is-clipped');
        } else {
          document.documentElement.classList.remove('is-clipped');
        }
      },
    },
  },
};
</script>

<style lang="sass">
.ease-out-quint
  transition-timing-function: cubic-bezier(0.230,  1.000, 0.320, 1.000)
.ui-fullscreen-modal__wrapper
  top: calc(constant(safe-area-inset-top) + theme('spacing.6'))
  top: calc(env(safe-area-inset-top) + theme('spacing.6'))
.ui-fullscreen-modal__main
  max-width: 768px
.ui-fullscreen-modal__content
  padding-bottom: calc(constant(safe-area-inset-bottom) + theme('padding.6'))
  padding-bottom: calc(env(safe-area-inset-bottom) + theme('padding.6'))
</style>
