<template>
  <transition
    enter-active-class="ease-out duration-200"
    enter-class="opacity-0 -translate-y-4"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="ease-in duration-100"
    leave-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 -translate-y-4"
  >
    <div v-if="open" class="ui-notification fixed left-0 right-0 p-4 transform transition z-30">
      <div class="py-2 px-6 rounded-xl shadow-lg text-sm w-full whitespace-pre-line" :class="color">
        {{ message }}
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UiNotification',
  computed: {
    ...mapGetters('notification', ['open', 'message', 'type']),
    color() {
      switch (this.type) {
        case 'success':
          return 'bg-blue-800 text-blue-200'
        case 'error':
          return 'bg-red-800 text-red-200'
        default:
          return 'bg-indigo-800 text-indigo-200'
      }
    },
  },
}
</script>

<style lang="sass">
.ui-notification
  top: constant(safe-area-inset-top)
  top: env(safe-area-inset-top)
</style>
