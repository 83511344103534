import axios from 'axios';

export default {
  async get() {
    const resp = await axios.get('/api/equipments');
    return resp.data;
  },
  async getOne(id) {
    const resp = await axios.get(`/api/equipments/${id}`);
    return resp.data;
  },
  async getPlaces() {
    const resp = await axios.get('/api/equipments/places');
    return resp.data;
  },
  async getCategories() {
    const resp = await axios.get('/api/equipments/categories');
    return resp.data;
  },
  async decrementStock(id) {
    const resp = await axios.patch(`/api/equipments/${id}/decrement`);
    return resp.data;
  },
  async create(data) {
    const resp = await axios.post('/api/equipments', data);
    return resp.data;
  },
  async update(id, data) {
    const resp = await axios.put(`/api/equipments/${id}`, data);
    return resp.data;
  },
  async remove(id) {
    const resp = await axios.delete(`/api/equipments/${id}`);
    return resp.data;
  },
  async getOrderTemplates() {
    const resp = await axios.get('/api/equipments/order-templates');
    return resp.data;
  },
};
