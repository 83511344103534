<template>
  <div>
    <div class="text-right mb-1 mr-1">
      <span class="text-xs text-sub">{{ value }} / {{ max }}</span>
    </div>
    <div class="w-full bg-level-3 h-1 rounded-full shadow-lg">
      <div
        class="transition-all duration-100 bg-indigo-600 h-full rounded-full w-full origin-left"
        :style="{
          transform: `scaleX(${(value/max)})`,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiProgress',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
  },
};
</script>
