import axios from 'axios'

export default {
  async getCollection() {
    const resp = await axios.get('/api/todo/collection')
    return resp.data
  },
  async getDocuments() {
    const resp = await axios.get('/api/todo/collection', { params: { document: true } })
    return resp.data
  },
  async getAllTodoItems(id, target = null) {
    if (target) {
      const resp = await axios.get(`/api/todo/all/${id}`, { params: { target } })
      return resp.data
    }
    const resp = await axios.get(`/api/todo/all/${id}`)
    return resp.data
  },
  async toggleCheckbox(data) {
    const resp = await axios.post('/api/todo/toggle-checkbox', data)
    return resp.data
  },
  async clearCheckbox(data) {
    const resp = await axios.post('/api/todo/clear-checkbox', data)
    return resp.data
  },
}
