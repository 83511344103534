<template>
  <header
    v-if="!$route.meta.root && $route.meta.title"
    class="ui-header fixed top-0 left-0 right-0 mt-0 w-full bg-main z-10"
  >
    <div class="px-6 pt-6 pb-3">
      <div class="flex items-center justify-between">
        <button
          type="button"
          class="w-6 h-6 focus:outline-none text-center bg-gray-900 bg-opacity-50 rounded-full"
          @click="$router.back()"
        >
          <font-awesome-icon
            class="text-sub text-md"
            :icon="['fas', 'chevron-left']"
          />
        </button>
        <h2
          class="text-md text-sub font-bold text-center tracking-widest flex-grow"
        >
          {{ $route.meta.title }}
        </h2>
        <router-link
          v-if="$route.meta.addButton && $route.meta.addButton.to"
          tag="button"
          type="button"
          class="w-6 h-6 focus:outline-none text-center bg-gray-900 bg-opacity-50 rounded-full"
          :to="$route.meta.addButton.to"
        >
          <font-awesome-icon
            class="text-sub text-md"
            :icon="['fas', 'plus']"
          />
        </router-link>
        <button
          v-else-if="$route.meta.addButton && $route.meta.addButton.call"
          tag="button"
          type="button"
          class="w-6 h-6 focus:outline-none text-center bg-gray-900 bg-opacity-50 rounded-full"
          @click="call($route.meta.addButton.call)"
        >
          <font-awesome-icon
            class="text-sub text-md"
            :icon="['fas', 'plus']"
          />
        </button>
        <div
          v-else
          class="w-6 h-6"
        />
      </div>
    </div>
  </header>
  <header
    v-else-if="!$route.meta.root"
    class="ui-header fixed top-0 left-0 right-0 w-full bg-transparent z-10"
  >
    <div class="px-6 pt-6 pb-3">
      <div class="inline-flex items-center">
        <button
          type="button"
          class="w-6 h-6 focus:outline-none text-center bg-gray-900 bg-opacity-50 rounded-full"
          @click="$router.back()"
        >
          <font-awesome-icon
            class="text-sub text-md"
            :icon="['fas', 'chevron-left']"
          />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'UiHeader',
  methods: {
    call(methodName) {
      this.$router.app.$children[0].$refs.currentView[methodName]();
    },
  },
};
</script>

<style lang="sass">
.ui-header
  max-width: 768px
  margin: 0 auto
  height: calc(60px + constant(safe-area-inset-top))
  height: calc(60px +  env(safe-area-inset-top))
  padding-top: constant(safe-area-inset-top)
  padding-top: env(safe-area-inset-top)
</style>
