<template>
  <transition-group
    v-if="group"
    enter-active-class="ease-out transform duration-300"
    enter-class="opacity-0 translate-y-2"
    enter-to-class="opacity-100 translate-y-0"
  >
    <slot />
  </transition-group>
  <transition
    v-else
    enter-active-class="ease-out transform duration-300"
    enter-class="opacity-0 translate-y-2"
    enter-to-class="opacity-100 translate-y-0"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'UiTransition',
  props: {
    group: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
