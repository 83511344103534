import UiAction from './UiAction.vue';
import UiAutoComplete from './UiAutoComplete.vue';
import UiButton from './UiButton.vue';
import UiCheckbox from './UiCheckbox.vue';
import UiContainer from './UiContainer.vue';
import UiDialog from './UiDialog.vue';
import UiDialogButton from './UiDialogButton.vue';
import UiFileUpload from './UiFileUpload.vue';
import UiHeader from './UiHeader.vue';
import UiInput from './UiInput.vue';
import UiLabel from './UiLabel.vue';
import UiLoader from './UiLoader.vue';
import UiNotification from './UiNotification.vue';
import UiNumberInput from './UiNumberInput.vue';
import UiProgress from './UiProgress.vue';
import UiProgressCircle from './UiProgressCircle.vue';
import UiRadio from './UiRadio.vue';
import UiSelect from './UiSelect.vue';
import UiSwitch from './UiSwitch.vue';
import UiTab from './UiTab.vue';
import UiTextarea from './UiTextarea.vue';
import UiTransition from './UiTransition.vue';
import UiFullscreenModal from './UiFullscreenModal.vue';

export default [
  UiAction,
  UiAutoComplete,
  UiButton,
  UiCheckbox,
  UiContainer,
  UiDialog,
  UiDialogButton,
  UiFileUpload,
  UiHeader,
  UiLabel,
  UiInput,
  UiLoader,
  UiNotification,
  UiNumberInput,
  UiProgress,
  UiProgressCircle,
  UiRadio,
  UiSelect,
  UiSwitch,
  UiTab,
  UiTextarea,
  UiTransition,
  UiFullscreenModal,
];
