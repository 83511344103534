<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  ><g><path d="m497 62.1h-47.1v-47.1c0-8.284-6.716-15-15-15h-419.9c-8.284 0-15 6.716-15 15v391c0 8.284 6.716 15 15 15h47.1v76c0 8.284 6.716 15 15 15h419.9c8.284 0 15-6.716 15-15v-419.9c0-8.285-6.716-15-15-15zm-15 30v58.9h-32.1v-58.9zm-62.1-62.1v61h-389.9v-61zm-389.9 91h389.9v270h-389.9zm62.1 361v-61h342.8c8.284 0 15-6.716 15-15v-225h32.1v301z" /><path d="m150.95 346v-60c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15zm-30-15h-30v-30h30z" /><path d="m196.05 181h179.9c8.284 0 15-6.716 15-15s-6.716-15-15-15h-179.9c-8.284 0-15 6.716-15 15s6.716 15 15 15z" /><path d="m196.05 239.95h149.9c8.284 0 15-6.716 15-15s-6.716-15-15-15h-149.9c-8.284 0-15 6.716-15 15s6.716 15 15 15z" /><path d="m196.05 302.05h179.9c8.284 0 15-6.716 15-15s-6.716-15-15-15h-179.9c-8.284 0-15 6.716-15 15s6.716 15 15 15z" /><path d="m196.05 361h149.9c8.284 0 15-6.716 15-15s-6.716-15-15-15h-149.9c-8.284 0-15 6.716-15 15s6.716 15 15 15z" /><path d="m67.52 222.238 21.768 15.05c6.897 4.771 16.475 2.979 21.115-4.176l38.232-58.95c4.508-6.95 2.527-16.239-4.423-20.747-6.952-4.509-16.24-2.526-20.747 4.423l-29.825 45.987-9.06-6.263c-6.813-4.711-16.157-3.007-20.869 3.808-4.711 6.815-3.006 16.157 3.809 20.868z" /></g></svg>
</template>

<script>
export default {
  name: 'IconChecklistPrompt',
};
</script>
