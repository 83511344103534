const stateKeysAndDefaultValues = {
  applicationUpdated: false,
}

const getters = {}
const mutations = {}

Object.keys(stateKeysAndDefaultValues).forEach(key => {
  getters[key] = state => state[key]
  mutations[key] = (state, value) => {
    state[key] = value
  }
})

export default {
  state: stateKeysAndDefaultValues,
  getters,
  mutations,
  namespaced: true,
}
