<template>
  <div>
    <input
      :id="id"
      class="hidden"
      type="file"
      accept="image/*"
      :multiple="multiple"
      :disabled="uploading"
      @input="onFileInput"
    />
    <label
      :for="id"
      class="rounded-xl text-center px-4 py-2 focus:outline-none bg-level-1 text-sub shadow-xl block"
      :class="buttonClass"
      ><slot
    /></label>
  </div>
</template>

<script>
import axios from 'axios'
import { nanoid } from 'nanoid'

export default {
  name: 'UiFileUpload',
  props: {
    target: {
      type: String,
      required: true,
    },
    targetId: {
      type: [String, Number],
      default: undefined,
    },
    buttonClass: {
      type: [Array, String, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: nanoid(),
      uploading: false,
    }
  },
  methods: {
    async onFileInput(e) {
      this.uploading = true
      this.$emit('upload-begin')
      const formData = new FormData()
      e.currentTarget.files.forEach(file => {
        formData.append('file', file)
      })
      try {
        let resp
        if (this.targetId) {
          resp = await axios.post(`/api/${this.target}/upload/${this.targetId}`, formData)
        } else {
          resp = await axios.post(`/api/${this.target}/upload`, formData)
        }
        resp.data.forEach(item => {
          this.$emit(
            'upload-ok',
            item.filename || { thumbnail: item.thumbnail, original: item.original },
          )
        })
      } catch {
        this.$emit('upload-failed')
        this.$store.dispatch('notification/set', {
          type: 'error',
          message: '画像のアップロードに失敗しました',
        })
      } finally {
        this.uploading = false
        this.$emit('upload-end')
      }
    },
  },
}
</script>
