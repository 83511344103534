<template>
  <div
    class="block mb-3 bg-level-3 shadow-xl rounded-lg border border-white border-opacity-25 cursor-pointer"
    @click="$emit('click', `/todo/${data.resource_data.collectionId}?c=${data.resource_data.id}`)"
  >
    <div class="px-3 py-2 text-white text-sm rounded-lg flex items-center">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="flex-grow search-result-item-content text-white text-opacity-75 min-w-0 whitespace-pre-line break-all"
        v-html="safeHtml"
      />
      <div class="flex-shrink-0 ml-2 text-opacity-75 text-white">
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </div>
    </div>
    <p class="text-white text-opacity-75 text-xxs truncate px-3 py-1 bg-level-1 rounded-b-lg">
      {{ data.resource_data.collectionName }}
    </p>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'SearchResultItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    safeHtml() {
      const escaped = sanitizeHtml(this.data.content, {
        allowedTags: ['span'],
        allowedClasses: {
          span: ['keyword'],
        },
        disallowedTagsMode: 'escape',
      })
      const urlRegExp = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi
      return escaped.replace(
        urlRegExp,
        '<span class="inline-block truncate w-full"><a class="text-purple-400 underline" href="$1" target="_blank">$1</a></span>',
      )
    },
  },
}
</script>

<style>
.search-result-item-content .keyword {
  color: #d3bb42 !important;
  font-weight: bold !important;
}
</style>
