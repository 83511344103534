<template>
  <div class="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
    <input
      v-model="computedValue"
      type="checkbox"
      name="toggle"
      class="form-switch absolute block w-6 h-6 rounded-full bg-gray-200 shadow-lg appearance-none transition duration-200 cursor-pointer focus:outline-none"
    >
    <label
      for="toggle"
      class="toggle-label block overflow-hidden h-6 rounded-full bg-level-3 cursor-pointer shadow-inset"
    />
  </div>
</template>

<script>
export default {
  name: 'UiSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
};
</script>

<style lang="sass">
.form-switch:checked
  @apply transform translate-x-4

.form-switch:checked + .toggle-label
  @apply bg-indigo-600
</style>
