import axios from 'axios'

export default {
  async get(mode) {
    const resp = await axios.get(`/api/schedules?${mode}`)
    return resp.data
  },
  async getUpcoming() {
    const resp = await axios.get('/api/schedules?recent=3&upcoming=true&detail=true')
    return resp.data
  },
  async getNear() {
    const resp = await axios.get(`/api/schedules?near=true`)
    return resp.data
  },
  async getRecent(count) {
    const resp = await axios.get(`/api/schedules?recent=${count}`)
    return resp.data
  },
  async getOne(id, detail) {
    const resp = await axios.get(`/api/schedules/${id}?detail=${!!detail}`)
    return resp.data
  },
  async start(device_id, id) {
    const resp = await axios.post(`/api/schedules/${id}/start`, { device_id })
    return resp.data
  },
  async reset(device_id, id) {
    await axios.post(`/api/schedules/${id}/reset`, { device_id })
  },
  async getToday() {
    const resp = await axios.get('/api/schedules/today')
    return resp.data
  },
  async getTweetBase({ scheduleId, shopId }) {
    const resp = await axios.get(
      `/api/utilities/tweet-base?scheduleId=${scheduleId}&shopId=${shopId}`,
    )
    return resp.data
  },
}
