<template>
  <div class="ui-loader mt-6 flex justify-center items-center">
    <div class="dot-overtaking" />
  </div>
</template>

<script>
export default {
  name: 'UiLoader',
};
</script>

<style lang="sass">
.ui-loader
  padding: 20px

@mixin dot
  @apply bg-transparent
  @apply text-sub
  width: 12px
  height: 12px
  border-radius: 6px

.dot-overtaking
  @include dot
  position: relative
  margin: -1px 0
  box-shadow: 0 -20px 0 0
  animation: dot-overtaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2)

  &::before, &::after
    @include dot
    content: ""
    display: inline-block
    position: absolute
    top: 0
    left: 0
    box-shadow: 0 -20px 0 0

  &::before
    animation: dot-overtaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2) 0.3s

  &::after
    animation: dot-overtaking 1.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2) 0.6s

@keyframes dot-overtaking
  0%
    transform: rotateZ(0deg)
  100%
    transform: rotateZ(360deg)
</style>
