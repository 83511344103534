<template>
  <label class="block">
    <p v-if="label" class="text-sub text-xs ml-1 mb-1">
      {{ label }}
      <span v-if="required" class="text-red-400">*</span>
    </p>
    <input
      ref="input"
      v-model="computedValue"
      :type="type"
      class="form-input border-none block w-full bg-level-1 shadow-xl focus:bg-level-3 text-main rounded-xl px-6 transition-colors duration-100"
      :placeholder="placeholder"
      :inputmode="numberOnly ? 'decimal' : undefined"
      :pattern="numberOnly ? '\\d*' : undefined"
    />
  </label>
</template>

<script>
export default {
  name: 'UiInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    numberOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
}
</script>
