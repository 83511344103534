<template>
  <ui-fullscreen-modal v-model="isOpen">
    <ui-input
      ref="input"
      v-model="query"
      type="search"
      class="mt-4"
      placeholder="検索する内容を入力"
    />
    <div class="mt-4">
      <ui-transition group>
        <div v-if="searching && searchResults.length === 0" :key="1" class="text-center text-sub">
          検索中です...
        </div>
        <div
          v-else-if="query && !searchAwaiting && searchResults.length === 0"
          :key="2"
          class="text-center text-sub"
        >
          検索結果がありません
        </div>
        <div v-else-if="searchResults.length > 0" :key="3">
          <search-result-item
            v-for="result in searchResults"
            :key="result.id"
            :data="result"
            @click="onClick"
          />
        </div>
      </ui-transition>
    </div>
  </ui-fullscreen-modal>
</template>

<script>
import { debounce } from 'lodash'
import UiFullscreenModal from '@/components/@ui/UiFullscreenModal'
import UiInput from '@/components/@ui/UiInput'
import SearchService from '@/services/SearchService'
import UiTransition from '@/components/@ui/UiTransition'
import SearchResultItem from '@/components/search/SearchResultItem'
export default {
  name: 'SearchModal',
  components: { SearchResultItem, UiTransition, UiInput, UiFullscreenModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searching: false,
      searchAwaiting: false,
      searchResults: [],
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    query: {
      get() {
        return this.$store.getters['app/searchInput']
      },
      set(value) {
        this.$store.commit('app/searchInput', value)
      },
    },
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen && this.query.trim()) {
        this.search()
      }
    },
    query(val) {
      if (!val) {
        this.searchResults = []
        return
      }
      this.searchAwaiting = true
      this.debouncedSearch()
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, 500)
  },
  methods: {
    async search() {
      this.searchAwaiting = false
      if (!this.query.trim()) {
        this.searchResults = []
        return
      }
      this.searching = true
      this.searchResults = await SearchService.exec(this.query.trim())
      this.searching = false
    },
    async onClick(route) {
      this.isOpen = false
      await this.$nextTick()
      await this.$router.push(route)
    },
  },
}
</script>
