<template>
  <div class="bg-today-suggestion shadow-xl rounded-2xl text-main">
    <div class="pt-3 pb-2 px-4 bg-white bg-opacity-75 rounded-t-2xl flex items-center shadow-xl">
      <img class="w-12 h-12 rounded-xl shadow-xl mr-3 object-cover" :src="preferThumbnail" />
      <div>
        <p class="text-xxs text-black text-opacity-75">
          本日は撮影日です。頑張りましょう！
        </p>
        <p class="text-xl font-extrabold text-black text-opacity-75">
          {{ schedule.title }}
        </p>
      </div>
    </div>
    <div class="bg-cool-gray-800 bg-opacity-90 pb-4 rounded-b-2xl">
      <h3 class="bg-cool-gray-900 bg-opacity-50 py-1 px-4 shadow-2xl text-opacity-75 text-xxs mb-2">
        進行中のタイムテーブル
      </h3>
      <div class="grid grid-cols-1 gap-2 px-4">
        <div v-if="loading" key="loading" class="my-2 flex items-center justify-center">
          <font-awesome-icon
            :icon="['fas', 'circle-notch']"
            class="text-sub text-opacity-50 text-3xl"
            spin
          />
        </div>
        <div
          v-else-if="schedule.is_canceled"
          key="schedule-canceled"
          class="text-2xl text-center my-1 font-bold font-number text-error-500"
        >
          撮影中止
        </div>
        <button
          v-else-if="!schedule.start_ts"
          key="start-schedule"
          type="button"
          class="flex-shrink-0 focus:outline-none rounded-full w-full py-1 text-lg bg-opacity-75 bg-level-1 border-2 border-opacity-75 border-success-500 shadow-2xl my-2 text-success-400 font-bold"
          @click="startConfirmDialogIsOpen = true"
        >
          撮影開始
        </button>
        <div v-else-if="currentTimetable" key="mark-as-end">
          <button
            type="button"
            class="flex-shrink-0 focus:outline-none rounded-full w-full py-2 text-lg bg-opacity-75 bg-level-1 border-4 border-opacity-75 border-pink-500 shadow-lg my-2 text-pink-400 font-bold"
            @click="markAsEnd"
          >
            <div class="flex justify-between items-center px-5">
              <p class="text-left">{{ currentTimetableName }}</p>
              <p class="text-xxs ml-1 tracking-tighter">ボタンを押して完了</p>
            </div>
          </button>
        </div>
        <div
          v-else
          key="all-end"
          class="text-2xl text-center my-1 font-bold font-number text-success-500"
        >
          撮影終了
        </div>
        <div class="text-center my-2">
          <router-link
            class="bg-black bg-opacity-50 tracking-widest text-sm px-6 py-2 rounded-2xl shadow-lg text-white text-opacity-75"
            :to="`/timetables/${scheduleId}`"
          >
            <font-awesome-icon :icon="['fas', 'clock']" class="mr-2" />
            本日の香盤表を確認
          </router-link>
        </div>
      </div>
      <h3
        class="bg-cool-gray-900 bg-opacity-50 py-1 px-4 shadow-2xl text-opacity-75 text-xxs mt-2 mb-2"
      >
        撮影臨時タスク
      </h3>
      <div class="grid grid-cols-1 gap-2">
        <template v-if="todoLists.length === 0">
          <p class="flex items-center justify-between px-4 text-sm text-gray-400">
            タスクは登録されていません
          </p>
        </template>
        <template v-else>
          <div
            v-for="todoList in todoLists"
            :key="todoList.id"
            class="flex items-center justify-between px-4"
          >
            <font-awesome-icon
              :icon="['fas', 'check-circle']"
              class="flex-shrink-0 mr-2 text-sm"
              :class="{
                'text-green-400': !!todoList.todo_items.every(v => v.checked),
                'text-gray-400': !todoList.todo_items.every(v => v.checked),
                'text-opacity-25': !todoList.todo_items.every(v => v.checked),
              }"
            />
            <p class="text-xs flex-grow">
              {{ todoList.name }}
            </p>
            <button
              type="button"
              class="flex-shrink-0 focus:outline-none rounded-full px-4 py-2 text-xxs bg-level-3 shadow-xl tracking-tight text-main font-bold"
              @click="navigateToTodoList(todoList.id)"
            >
              確認
            </button>
          </div>
        </template>
      </div>
    </div>
    <tweet-template-dialog v-model="tweetCopyDialog" :schedule-id="scheduleId" />
    <qrcode-modal v-model="qrCodeDialog" :qrcode="schedule.qrcode" />
    <ui-dialog
      v-model="startConfirmDialogIsOpen"
      title="撮影開始します。よろしいですか？"
      class="z-40"
    >
      <ui-button
        color="primary"
        class="mb-2"
        @click="
          startConfirmDialogIsOpen = false
          startSchedule()
        "
      >
        撮影開始
      </ui-button>
      <ui-button @click="startConfirmDialogIsOpen = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>
<script>
import { format } from 'date-fns'
import TodoService from '@/services/TodoService'
import ScheduleService from '@/services/ScheduleService'
import TimetableService from '@/services/TimetableService'
import TweetTemplateDialog from '../schedule/TweetTemplateDialog.vue'
import QrcodeModal from './QrcodeModal.vue'

export default {
  name: 'TodaySuggestion',
  components: {
    TweetTemplateDialog,
    QrcodeModal,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tweetCopyDialog: false,
      scheduleId: null,
      todoId: null,
      previousTimetable: null,
      currentTimetable: null,
      qrCodeDialog: false,
      todoLists: [],
      loading: true,
      startConfirmDialogIsOpen: false,
    }
  },
  computed: {
    preferThumbnail() {
      if (this.schedule.thumbnail) {
        return `/uploads/schedules/${this.schedule.thumbnail}`
      }
      return `/uploads/actresses/${this.schedule.actresses[0].thumbnail}`
    },
    currentTimetableName() {
      return this.currentTimetable
        ? `${this.currentTimetable.label ? `${this.currentTimetable.label.name} ` : ''}${
            this.currentTimetable.scene
          }`
        : ''
    },
    endGoal() {
      let d
      if (!this.previousTimetable) {
        d = new Date(this.schedule.start_ts).getTime() + this.currentTimetable.time_minute * 60000
      } else {
        d =
          new Date(this.previousTimetable.end_ts).getTime() +
          this.currentTimetable.time_minute * 60000
      }
      return format(d, 'HH:mm')
    },
  },
  watch: {
    schedule: {
      immediate: true,
      async handler(value) {
        if (!value) {
          return
        }
        this.scheduleId = value.id
        this.loading = true
        const [todo, previousTimetable, currentTimetable] = await Promise.all([
          TodoService.getAllTodoItems(this.scheduleId, 'schedule'),
          TimetableService.getPrevious(this.scheduleId),
          TimetableService.getCurrent(this.scheduleId),
        ])
        this.previousTimetable = previousTimetable
        this.currentTimetable = currentTimetable
        if (!todo) {
          this.todoId = null
          this.todoLists = []
        } else {
          this.todoId = todo.id
          this.todoLists = todo.todo_lists
        }
        this.loading = false
      },
    },
  },
  async created() {
    await this.subscribe()
  },
  beforeDestroy() {
    this.stopWsReconnectTimer()
    this.stopWsHealthCheckTimer()
    this.ws.close()
  },
  methods: {
    async startSchedule() {
      await ScheduleService.start(this.$deviceId, this.scheduleId)
      this.schedule.start_ts = new Date()
      await this.refetch()
    },
    async markAsEnd() {
      await TimetableService.markAsEnd(this.$deviceId, this.currentTimetable.id)
      await this.refetch()
    },
    async refetch() {
      const [previousTimetable, currentTimetable] = await Promise.all([
        TimetableService.getPrevious(this.scheduleId),
        TimetableService.getCurrent(this.scheduleId),
      ])
      this.previousTimetable = previousTimetable
      this.currentTimetable = currentTimetable
      if (!this.schedule.start_ts) {
        const schedule = await ScheduleService.getOne(this.scheduleId)
        if (schedule && schedule.start_ts) {
          this.schedule.start_ts = new Date(schedule.start_ts)
        }
      }
    },
    navigateToTodoList(listId) {
      this.$router.push(`/todo/${this.todoId}?listId=${listId}`)
    },
    subscribe() {
      return new Promise(resolve => {
        this.ws = new WebSocket('wss://fmapp.net/ws/connection')
        this.ws.onopen = () => resolve()
        this.ws.onmessage = event => {
          if (event.data === 'pong') {
            this.stopWsReconnectTimer()
            return
          }
          const data = JSON.parse(event.data)
          if (data.deviceId === this.$deviceId) return
          if (data.app !== 'timetable') return
          if (Number(data.scheduleId) !== Number(this.scheduleId)) return
          this.refetch()
        }

        this.wsHealthCheckTimer = setInterval(() => {
          this.ws.send('ping')
          this.startWsReconnectTimer()
        }, 10000)
      })
    },
    startWsReconnectTimer() {
      this.wsReconnectTimer = setTimeout(() => {
        this.stopWsHealthCheckTimer()
        this.ws.close()
        this.subscribe()
        this.refetch()
      }, 3000)
    },
    stopWsReconnectTimer() {
      clearTimeout(this.wsReconnectTimer)
    },
    stopWsHealthCheckTimer() {
      clearInterval(this.wsHealthCheckTimer)
    },
  },
}
</script>
<style>
.bg-today-suggestion {
  background: linear-gradient(to right, #dc2424, #4a569d);
}
</style>
