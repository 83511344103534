<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 480 480"
    version="1.1"
    viewBox="0 0 480 480"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="m232 336h-48c-4.418 0-8 3.582-8 8v40c0 4.418 3.582 8 8 8h48c4.418 0 8-3.582 8-8v-40c0-4.418-3.582-8-8-8zm-8 40h-32v-24h32v24z" /><rect
    x="384"
    y="344"
    width="16"
    height="16"
  /><rect
    x="416"
    y="344"
    width="32"
    height="16"
  /><rect
    x="384"
    y="376"
    width="64"
    height="16"
  /><path d="m479.94 127.71c-0.034-0.842-0.201-1.674-0.496-2.464-0.072-0.176-0.112-0.36-0.192-0.528s-0.048-0.2-0.096-0.296l-32-64c-1.355-2.712-4.128-4.425-7.16-4.424h-256c-3.032-1e-3 -5.805 1.712-7.16 4.424l-32 64c-0.048 0.096-0.056 0.2-0.104 0.296s-0.112 0.352-0.184 0.528c-0.295 0.79-0.462 1.622-0.496 2.464 0 0.096-0.056 0.184-0.056 0.288v40h-144v16h144v16h-112v16h112v200c0 4.418 3.582 8 8 8h320c4.418 0 8-3.582 8-8v-288c0-0.104-0.056-0.184-0.056-0.288zm-44.888-55.712 24 48h-108l-6-48h90zm-139.99 0h33.872l6 48h-45.872l6-48zm-7.064 64h48v96h-48v-96zm-99.064-64h90l-6 48h-108l24-48zm275.06 336h-304v-192h48v-16h-48v-16h48v-16h-48v-32h112v104c0 4.418 3.582 8 8 8h64c4.418 0 8-3.582 8-8v-104h112v272z" /><rect
    y="304"
    width="128"
    height="16"
  /><rect
    x="32"
    y="336"
    width="96"
    height="16"
  /></svg>
</template>

<script>
export default {
  name: 'IconBoxMove',
};
</script>
