<template>
  <div
    class="rounded-full"
    :class="{
      'bg-gray-800': color === 'gray',
      'text-gray-400': color === 'gray',
      'bg-orange-700': color === 'orange',
      'text-orange-200': color === 'orange',
      'bg-success-800': color === 'success',
      'text-success-100': color === 'success',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiLabel',
  props: {
    size: {
      type: String,
      default: 'xs',
    },
    color: {
      type: String,
      default: 'gray',
    },
  },
}
</script>
