<template>
  <div
    class="ui-container"
  >
    <div
      class="px-6 pb-6"
      :class="{ 'pt-6': !!$route.meta.title }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiContainer',
};
</script>

<style lang="sass">
  .ui-container
    padding-top: calc(60px + constant(safe-area-inset-top))
    padding-top: calc(60px + env(safe-area-inset-top))
    padding-bottom: constant(safe-area-inset-bottom)
    padding-bottom: env(safe-area-inset-bottom)
</style>
