import axios from 'axios';

export default {
  async getTodaySuggestion(scheduleId) {
    const resp = await axios.get('/api/suggestions/today', { params: { schedule_id: scheduleId } });
    return resp.data;
  },
  async getSampleTweetIds() {
    const resp = await axios.get('/api/utilities/sample-tweet-ids');
    return resp.data;
  },
};
