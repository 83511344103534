<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="m166 60c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15s15-6.716 15-15v-90c0-8.284-6.716-15-15-15z" /><path d="m226 60c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15s15-6.716 15-15v-90c0-8.284-6.716-15-15-15z" /><path d="m286 60c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15s15-6.716 15-15v-90c0-8.284-6.716-15-15-15z" /><path d="m346 90c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15s15-6.716 15-15v-60c0-8.284-6.716-15-15-15z" /><path d="m436 180c8.284 0 15-6.716 15-15v-47.573c0-12.02-4.681-23.32-13.18-31.82l-72.426-72.427c-8.5-8.499-19.8-13.18-31.82-13.18h-227.57c-24.813 0-45 20.187-45 45v120c0 8.284 6.716 15 15 15h15v32h-15c-8.284 0-15 6.716-15 15v240c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-240c0-8.284-6.716-15-15-15h-15v-32h15zm-30 62h15v225c0 8.271-6.729 15-15 15h-300c-8.271 0-15-6.729-15-15v-225h15c8.284 0 15-6.716 15-15v-62c0-8.284-6.716-15-15-15h-15v-105c0-8.271 6.729-15 15-15h227.57c4.006 0 7.773 1.56 10.607 4.394l72.426 72.426c2.833 2.833 4.394 6.6 4.394 10.607v32.573h-15c-8.284 0-15 6.716-15 15v62c-1e-3 8.284 6.715 15 14.999 15z" /><path d="m376 272h-240c-8.284 0-15 6.716-15 15v150c0 8.284 6.716 15 15 15h240c8.284 0 15-6.716 15-15v-150c0-8.284-6.716-15-15-15zm-15 150h-210v-120h210v120z" /><path d="m316 362h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z" /></svg>
</template>

<script>
export default {
  name: 'IconSdCard',
};
</script>
