<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  ><path d="M61,38H60V35a1,1,0,0,0-1-1H55a1,1,0,0,0-1,1v3H52V29a1,1,0,0,0-1-1H50V25a1,1,0,0,0-1-1H45a1,1,0,0,0-1,1v3H42V20h9a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H50V5a1,1,0,0,0-1-1H45a1,1,0,0,0-1,1V8H40V5a1,1,0,0,0-1-1H35a1,1,0,0,0-1,1V8H33a1,1,0,0,0-1,1v9H30V15a1,1,0,0,0-1-1H25a1,1,0,0,0-1,1v3H20V15a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1v3H13a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1h9v8H20V35a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1v3H10V35a1,1,0,0,0-1-1H5a1,1,0,0,0-1,1v3H3a1,1,0,0,0-1,1V59a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1,7,7,0,0,1,14,0,1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V40H42V59a1,1,0,0,0,1,1H61a1,1,0,0,0,1-1V39A1,1,0,0,0,61,38Zm-5-2h2v2H56ZM46,26h2v2H46ZM46,6h2V8H46ZM36,6h2V8H36Zm-2,4H50v8H34Zm-8,6h2v2H26ZM16,16h2v2H16Zm-2,4H40v8H14ZM24,30H50v8H24Zm-8,6h2v2H16ZM6,36H8v2H6ZM30,58H25.944A9,9,0,0,0,8.056,58H4V44H22V42H4V40H30ZM51,40h9v8H44V40ZM44,58V50H60v8Z" /><rect
    x="24"
    y="42"
    width="2"
    height="2"
  /></svg>
</template>

<script>
export default {
  name: 'IconLego',
};
</script>
