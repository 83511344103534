import axios from 'axios';

export default {
  async get(scheduleId) {
    let url;
    if (scheduleId === 'default') {
      url = '/api/merchandises?closet';
    } else {
      url = `/api/merchandises?scheduleId=${scheduleId}`;
    }
    const resp = await axios.get(url);
    return resp.data;
  },

  async getOne(id) {
    const resp = await axios.get(`/api/merchandises/${id}`);
    return resp.data;
  },

  async getTodoList() {
    const resp = await axios.get('/api/merchandises/todo');
    return resp.data;
  },

  async create(data) {
    const resp = await axios.post('/api/merchandises', data);
    return resp.data;
  },

  async update(id, data) {
    const resp = await axios.put(`/api/merchandises/${id}`, data);
    return resp.data;
  },

  async delete(id) {
    const resp = await axios.delete(`/api/merchandises/${id}`);
    return resp.data;
  },

  async registerImage(id, data) {
    const resp = await axios.post(`/api/merchandises/image/${id}`, data);
    return resp.data;
  },

  async updateAmount(merchandiseAmountId, amount) {
    const resp = await axios.patch(`/api/merchandises/amount/${merchandiseAmountId}`, { amount });
    return resp.data;
  },
  async checkTodo(merchandiseId, todoId) {
    await axios.post('/api/merchandises/todo/check', {
      merchandise_id: merchandiseId,
      merchandise_todo_item_id: todoId,
    });
    return null;
  },
  async uncheckTodo(id) {
    await axios.post('/api/merchandises/todo/uncheck', { id });
    return null;
  },
  async removeImage(id) {
    await axios.delete(`/api/merchandises/image/${id}`);
    return null;
  },
  async transferToCostume(id) {
    await axios.post(`api/merchandise/image/transfer_to_costume/${id}`);
    return null;
  },
};
