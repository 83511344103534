var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-full",class:{
    'bg-gray-800': _vm.color === 'gray',
    'text-gray-400': _vm.color === 'gray',
    'bg-orange-700': _vm.color === 'orange',
    'text-orange-200': _vm.color === 'orange',
    'bg-success-800': _vm.color === 'success',
    'text-success-100': _vm.color === 'success',
  }},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }