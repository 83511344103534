<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 512.001 512.001"
    version="1.1"
    viewBox="0 0 512.001 512.001"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M444.522,241.218V55.449c0-9.227-7.152-16.713-16.378-16.713s-16.991,7.486-16.991,16.713v185.694
    c-58.051,13.366-118.971,95.623-143.48,131.848h-17.8c-31.656-46.137-95.45-120.327-150.746-132.102V55.449
    c0-9.227-7.152-16.713-16.378-16.713s-16.936,7.486-16.936,16.713v186.043C26.129,250.961,0,290.379,0,343.807
    c0,32.076,10.402,63.75,28.551,86.904c21.805,27.843,52.88,42.554,89.853,42.554c57.678,0,98.557-20.707,131.366-66.851h18
    c33.543,47.95,69.518,66.851,125.84,66.851c36.983,0,68.047-14.711,89.863-42.533c18.128-23.143,28.529-54.805,28.529-86.883
    C512,289.899,484.831,250.209,444.522,241.218z"
  />
  </svg>
</template>

<script>
export default {
  name: 'IconBra',
};
</script>
