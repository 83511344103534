import axios from 'axios'
import EquipmentService from './EquipmentService'
import MerchandiseService from './MerchandiseService'

export default {
  async getOrders() {
    const resp = await axios.get('/api/orders')
    return resp.data
  },

  async createMerchandiseOrder(item) {
    await axios.post('/api/orders', {
      name: item.name,
      category: 'merchandise',
      status: 'purchased',
      schedule_id: item.schedule_id,
      url: item.url,
      params: JSON.stringify({
        name: item.name,
        schedule_id: item.schedule_id,
        label_id: item.label_id,
        merchandise_category_id: 3,
        actress_id: item.actress_id,
      }),
    })
  },

  async createEquipmentOrder(item) {
    await axios.post('/api/orders', {
      name: item.name,
      category: 'equipment',
      status: 'purchased',
      schedule_id: item.schedule_id,
      url: item.url,
      params: JSON.stringify({
        name: item.name,
        stock: item.stock,
        thumbnail: '',
        equipment_place_id: item.equipment_place_id,
        equipment_category_id: item.equipment_category_id,
        purchase_url: item.url,
        comment: '',
      }),
    })
  },

  async acceptMerchandiseOrder(item, additionalInfo) {
    const COSTUME_PHOTO_UPLOADED = 11
    const id = await MerchandiseService.create({ order_item_id: item.id, ...item.params })
    if (additionalInfo.original && additionalInfo.thumbnail) {
      await Promise.all([
        MerchandiseService.registerImage(id, additionalInfo),
        MerchandiseService.checkTodo(id, COSTUME_PHOTO_UPLOADED),
      ])
    }
    await this.removeOrder(item.id)
  },

  async acceptEquipmentOrder(item, additionalInfo) {
    const request = { ...item.params }
    request.equipment_place_id = additionalInfo.equipmentPlaceId
    request.thumbnail = additionalInfo.thumbnail
    await EquipmentService.create(request)
    await this.removeOrder(item.id)
  },

  async removeOrder(orderId) {
    await axios.delete(`/api/orders/${orderId}`)
  },

  async getThumbnailFromUrl(url, cancelToken) {
    try {
      const resp = await axios.post(
        '/api/utilities/scrape-image',
        { url },
        { timeout: 0, cancelToken },
      )
      return resp.data
    } catch {
      return ''
    }
  },
}
