<template>
  <ui-fullscreen-modal v-model="modalOpen">
    <h3 class="qrcode-heading font-bold text-base text-center my-3">
      このQRコードをスキャンしてください！
    </h3>
    <div
      v-if="qrcode"
      class="px-2"
    >
      <img
        :src="`/uploads/schedules/qrcode/${qrcode}`"
        class="shadow-xl rounded-md"
      >
    </div>
    <ui-transition>
      <div
        v-if="ready"
        class="px-2"
      >
        <h3 class="tweet-heading text-sm font-bold text-center mt-8">
          <div class="flex items-center justify-center mb-1">
            <img
              class="w-8"
              src="../../assets/emoji/1f647-2640.png"
            >
            <img
              class="w-8"
              src="../../assets/emoji/1f647.png"
            >
            <img
              class="w-8"
              src="../../assets/emoji/1f647-2642.png"
            >
          </div>
          他の女優さんのツイート一例です
        </h3>
        <div class="flex flex-no-wrap overflow-x-auto">
          <tweet
            v-for="tweetId in tweetIdList"
            :id="tweetId"
            :key="tweetId"
            widget-class="qrcode-modal-tweets__tweet flex-grow-0 flex-shrink-0 w-4/5"
            :options="{ theme: 'dark' }"
          >
            <div class="flex items-center justify-center h-72">
              <font-awesome-icon
                :icon="['fas', 'circle-notch']"
                class="text-cool-gray-700 text-3xl mx-auto"
                spin
              />
            </div>
          </tweet>
        </div>
        <h3 class="text-red-200 text-sm font-bold tracking-widest text-center mb-2 mt-2 py-4 bg-black bg-opacity-25 rounded-2xl shadow-xl">
          <img
            src="../../assets/emoji/1f497.png"
            class="w-8 mx-auto mb-1"
          >
          ご協力ありがとうございます！
        </h3>
      </div>
    </ui-transition>
  </ui-fullscreen-modal>
</template>

<script>
import { Tweet } from 'vue-tweet-embed';
import UtilityService from '../../services/UtilityService';

export default {
  name: 'QrcodeModal',
  components: {
    Tweet,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    qrcode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tweetIdList: [],
      ready: false,
    };
  },
  computed: {
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(open) {
        if (!open) {
          this.ready = false;
          this.tweetIdList = [];
          return;
        }
        setTimeout(() => {
          this.fetch().then(() => {
            this.ready = true;
          });
        }, 300);
      },
    },
  },
  methods: {
    async fetch() {
      this.tweetIdList = await UtilityService.getSampleTweetIds();
    },
  },
};
</script>

<style lang="sass">
.qrcode-heading
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.tweet-heading
  background-image: linear-gradient(to right, #74ebd5 0%, #9face6 100%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.qrcode-modal-tweets__tweet
  flex-basis: auto
  margin-right: theme('margin.2')
</style>
