import IconCalenderWithCamera from './IconCalenderWithCamera.vue';
import IconBoxMove from './IconBoxMove.vue';
import IconBoxOpen from './IconBoxOpen.vue';
import IconBoxCheck from './IconBoxCheck.vue';
import IconBra from './IconBra.vue';
import IconCargo from './IconCargo.vue';
import IconChart from './IconChart.vue';
import IconChecklistPrompt from './IconChecklistPrompt.vue';
import IconClipboardWithPen from './IconClipboardWithPen.vue';
import IconCloset from './IconCloset.vue';
import IconCog from './IconCog.vue';
import IconFanza from './IconFanza.vue';
import IconFashion from './IconFashion.vue';
import IconHouseCleaning from './IconHouseCleaning.vue';
import IconInstagram from './IconInstagram.vue';
import IconLego from './IconLego.vue';
import IconRosette from './IconRosette.vue';
import IconSdCard from './IconSdCard.vue';
import IconShop from './IconShop.vue';
import IconTestTube from './IconTestTube.vue';
import IconUnderwear from './IconUnderwear.vue';

export default [
  IconCalenderWithCamera,
  IconBoxMove,
  IconBoxOpen,
  IconBoxCheck,
  IconBra,
  IconCargo,
  IconChart,
  IconChecklistPrompt,
  IconClipboardWithPen,
  IconCloset,
  IconCog,
  IconFanza,
  IconFashion,
  IconHouseCleaning,
  IconInstagram,
  IconLego,
  IconRosette,
  IconSdCard,
  IconShop,
  IconTestTube,
  IconUnderwear,
];
