<template>
  <ui-fullscreen-modal v-model="modalOpen">
    <ui-transition>
      <div v-if="actress.id">
        <figure class="actress-image bg-center bg-no-repeat bg-contain p-1">
          <img
            :src="actress.thumbnail ? `/uploads/actresses/${actress.thumbnail}` : null"
            class="w-36 h-36 object-cover shadow-2xl m-auto mb-4 rounded-full border-4 border-gray-900 bg-gray-400"
          />
        </figure>
        <div class="text-center mb-4">
          <h2 class="text-3xl text-main font-extrabold">
            {{ actress.name }}
          </h2>
          <p class="text-sm text-sub">
            {{ actress.name_pronounce }}
          </p>
          <p class="text-xs text-sub mb-2">{{ actress.birthday | age }}歳</p>
          <ui-label class="text-xs inline-block m-auto px-4 py-1">
            {{ actress.actress_production ? actress.actress_production.name : '---' }}
          </ui-label>
        </div>
        <div class="grid grid-cols-3 mb-5">
          <a
            v-if="actress.twitter_id"
            class="flex justify-center items-center"
            target="_blank"
            :href="`https://twitter.com/${actress.twitter_id}`"
          >
            <font-awesome-icon class="text-xl twitter-icon" :icon="['fab', 'twitter']" />
          </a>
          <div v-else class="flex justify-center items-center">
            <font-awesome-icon class="text-xl text-disabled" :icon="['fab', 'twitter']" />
          </div>
          <a
            v-if="actress.instagram_id"
            class="flex justify-center items-center"
            target="_blank"
            :href="`https://instagram.com/${actress.instagram_id}`"
          >
            <icon-instagram class="w-5 inline" />
          </a>
          <div v-else class="flex justify-center items-center">
            <font-awesome-icon class="text-2xl text-disabled" :icon="['fab', 'instagram']" />
          </div>
          <a
            v-if="actress.dmm_id"
            class="flex justify-center items-center"
            target="_blank"
            :href="
              `https://www.dmm.co.jp/digital/videoa/-/list/=/article=actress/id=${actress.dmm_id}/limit=120/sort=ranking/`
            "
          >
            <icon-fanza class="w-14 inline fanza-icon" />
          </a>
          <div v-else class="flex justify-center items-center text-disabled">
            <icon-fanza class="w-14 inline" />
          </div>
        </div>
        <div class="grid grid-cols-4 mb-5">
          <div class="text-center">
            <p class="text-xs text-sub">
              身長
            </p>
            <p class="text-2xl text-main font-bold">
              {{ actress.height || '---' }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-xs text-sub">
              バスト
            </p>
            <p class="text-2xl text-main font-bold">
              {{ actress.bust || '---' }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-xs text-sub">
              ウエスト
            </p>
            <p class="text-2xl text-main font-bold">
              {{ actress.waist || '---' }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-xs text-sub">
              ヒップ
            </p>
            <p class="text-2xl text-main font-bold">
              {{ actress.hip || '---' }}
            </p>
          </div>
        </div>
        <div class="grid grid-cols-3 mb-5">
          <div class="text-center">
            <p class="text-xs text-sub">
              洋服
            </p>
            <p class="text-2xl text-main font-bold">
              {{ actress.clothing_size ? actress.clothing_size.name : '---' }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-xs text-sub">
              ブラ
            </p>
            <p class="text-2xl text-main font-bold">
              {{ actress.bra_size ? actress.bra_size.name : '---' }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-xs text-sub">
              靴
            </p>
            <p class="text-2xl text-main font-bold">
              {{ actress.shoes_size ? actress.shoes_size.name : '---' }}
            </p>
          </div>
        </div>
        <div class="text-center mb-5">
          <p class="text-xs text-sub">
            趣味・特技
          </p>
          <p class="text-lg text-main">
            {{ actress.interests || '---' }}
          </p>
        </div>
        <div class="text-center">
          <p class="text-xs text-sub">
            備考
          </p>
          <p class="text-main">
            {{ actress.comment || '---' }}
          </p>
          <p v-if="actress.blog_url" class="text-sm mt-4 text-indigo-500">
            <a :href="actress.blog_url" target="_blank">Webサイト</a>
          </p>
        </div>
      </div>
    </ui-transition>
  </ui-fullscreen-modal>
</template>

<script>
import { parse, differenceInYears } from 'date-fns'
import ActressService from '../../services/ActressService'

export default {
  name: 'ActressModal',
  filters: {
    age(birthday) {
      if (!birthday) {
        return '--'
      }
      return differenceInYears(new Date(), parse(birthday, 'yyyy-MM-dd', new Date()))
    },
  },
  props: {
    actressId: {
      type: Number,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: true,
    actress: {},
  }),
  computed: {
    modalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    actressId: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.fetch()
      },
    },
  },
  methods: {
    async fetch() {
      this.actress = {}
      this.isLoading = true
      this.actress = await ActressService.get(this.actressId)
      this.isLoading = false
    },
  },
}
</script>

<style lang="sass">
.twitter-icon
  color: #1da1f2

.instagram-icon
  color: #E09B3D

.fanza-icon
  color: #EE2737

.actress-image
  background-image: url('../../assets/circle-background.svg')
</style>
