<template>
  <label class="block">
    <p
      v-if="label"
      class="text-sub text-xs ml-1 mb-1"
      v-text="label"
    />
    <input
      v-model="computedValue"
      :list="_uid"
      type="text"
      class="form-input border-none block w-full bg-level-1 shadow-xl focus:bg-level-3 text-main rounded-xl px-6 transition-colors duration-100"
      :placeholder="placeholder"
    >
    <datalist :id="_uid">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.text"
      />
    </datalist>
  </label>
</template>

<script>
export default {
  name: 'UiAutoComplete',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
};
</script>
