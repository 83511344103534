import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: null,
      root: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isPublic: true,
      title: null,
      root: true,
    },
  },
  {
    path: '/signup',
    name: 'InputInvitationCode',
    component: () => import(/* webpackChunkName: "signup" */ '../views/InputInvitationCode.vue'),
    meta: {
      isPublic: true,
      title: null,
      root: true,
    },
  },
  {
    path: '/signup/:code',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
    props: true,
    meta: {
      isPublic: true,
      root: true,
      title: null,
    },
  },
  {
    path: '/equipments',
    name: 'Equipment',
    component: () => import(/* webpackChunkName: "equipment" */ '../views/Equipment.vue'),
    meta: {
      title: '備品管理',
      addButton: { to: '/equipments/edit/new' },
    },
  },
  {
    path: '/equipments/edit/new',
    name: 'EquipmentNew',
    component: () => import(/* webpackChunkName: "equipment-edit" */ '../views/EquipmentEdit.vue'),
    props: true,
    meta: {
      title: '備品登録',
    },
  },
  {
    path: '/equipments/edit/:id',
    name: 'EquipmentEdit',
    component: () => import(/* webpackChunkName: "equipment-edit" */ '../views/EquipmentEdit.vue'),
    props: true,
    meta: {
      title: '備品編集',
    },
  },
  {
    path: '/costumes',
    name: 'Costume',
    component: () => import(/* webpackChunkName: "costume" */ '../views/Costume.vue'),
    meta: {
      title: '衣装管理',
      addButton: { to: '/costumes/edit/new' },
    },
  },
  {
    path: '/costumes/edit/new',
    name: 'CostumeNew',
    component: () => import(/* webpackChunkName: "costume-edit" */ '../views/CostumeEdit.vue'),
    props: true,
    meta: {
      title: '衣装登録',
    },
  },
  {
    path: '/costumes/edit/:id',
    name: 'CostumeEdit',
    component: () => import(/* webpackChunkName: "costume-edit" */ '../views/CostumeEdit.vue'),
    props: true,
    meta: {
      title: '衣装編集',
    },
  },
  {
    path: '/schedules',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
    meta: {
      title: '撮影一覧',
    },
  },
  {
    path: '/schedules/:id',
    name: 'ScheduleDetail',
    component: () =>
      import(/* webpackChunkName: "schedule-detail" */ '../views/ScheduleDetail.vue'),
    props: true,
    meta: {
      title: null,
    },
  },
  {
    path: '/timetables/:id',
    name: 'Timetable',
    component: () => import(/* webpackChunkName: "schedule-detail" */ '../views/Timetable.vue'),
    props: true,
    meta: {
      title: '香盤表',
      addButton: { call: 'openEditDialog' },
    },
  },
  {
    path: '/merchandise_todo/:id',
    name: 'Merchandise',
    component: () => import(/* webpackChunkName: "merchandise" */ '../views/Merchandise.vue'),
    props: true,
    meta: {
      title: '物販',
      addButton: { call: 'createMerchandise' },
    },
  },
  {
    path: '/studio_todo',
    name: 'StudioTodo',
    component: () => import(/* webpackChunkName: "studio-todo" */ '../views/StudioTodo.vue'),
    meta: {
      title: 'スタジオ片付け',
    },
  },
  {
    path: '/todo',
    name: 'TodoSelection',
    component: () => import(/* webpackChunkName: "todo-selection" */ '../views/TodoSelection.vue'),
    meta: {
      title: '業務チェックリスト',
    },
  },
  {
    path: '/documents',
    name: 'DocumentSelection',
    component: () => import(/* webpackChunkName: "todo-selection" */ '../views/TodoSelection.vue'),
    meta: {
      title: '業務マニュアル',
    },
  },
  {
    path: '/todo/:id',
    name: 'Todo',
    component: () => import(/* webpackChunkName: "todo" */ '../views/Todo.vue'),
    props: true,
    meta: {
      title: '業務チェックリスト',
    },
  },
  {
    path: '/documents/:id',
    name: 'Document',
    component: () => import(/* webpackChunkName: "todo-selection" */ '../views/Todo.vue'),
    props: true,
    meta: {
      title: '業務マニュアル',
    },
  },
  {
    path: '/properties',
    name: 'PropertySelection',
    component: () =>
      import(/* webpackChunkName: "property-selection" */ '../views/PropertySelection.vue'),
    meta: {
      title: 'マスタ編集',
    },
  },
  {
    path: '/properties/:name',
    name: 'PropertyEdit',
    props: true,
    component: () => import(/* webpackChunkName: "property-edit" */ '../views/PropertyEdit.vue'),
    meta: {
      title: 'マスタ編集',
      addButton: { call: 'create' },
    },
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import(/* webpackChunkName: "sales" */ '../views/Sales.vue'),
    meta: {
      title: '売上分析',
    },
  },
  {
    path: '/sales/:id',
    name: 'SalesProduct',
    component: () => import(/* webpackChunkName: "sales" */ '../views/SalesProduct.vue'),
    props: true,
    meta: {
      title: '売上分析 - 商品詳細',
    },
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "order" */ '../views/Order.vue'),
    meta: {
      title: '衣装・備品発注',
      addButton: { call: 'createOrder' },
    },
  },
  {
    path: '/preproduction/:id',
    name: 'Preproduction',
    component: () => import(/* webpackChunkName: "preproduction" */ '../views/Preproduction.vue'),
    props: true,
    meta: {
      title: '制作タスク',
    },
  },
  {
    path: '/error/:error',
    name: 'ServerError',
    component: () => import(/* webpackChunkName: "server-error" */ '../views/ServerError.vue'),
    props: true,
    meta: {
      title: 'エラー',
      isPublic: true,
      root: true,
    },
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.fullPath !== '/') {
      store.commit('layout/savePosition', savedPosition.y)
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta.isPublic) && !store.getters['auth/isAuthenticated']) {
    next('/login')
  } else {
    next()
  }
})

export default router
