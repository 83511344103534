<template>
  <div v-show="wrapper" class="fixed px-4 inset-0 flex items-center justify-center z-10">
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      @before-enter="wrapper = true"
      @after-leave="wrapper = false"
    >
      <div v-if="computedValue" class="ui-dialog__overlay fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-75" />
      </div>
    </transition>

    <transition
      enter-active-class="ease-out transform duration-300"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="ease-in transform duration-200"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-if="computedValue"
        class="bg-level-3 rounded-xl overflow-hidden shadow-xl transform transition-all w-full z-20 max-w-lg"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-level-3 px-4 pt-5 pb-4">
          <div>
            <div
              v-if="type"
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12"
            >
              <font-awesome-icon
                v-if="type === 'error'"
                class="text-red-500 text-3xl"
                :icon="['fas', 'exclamation-triangle']"
              />
              <font-awesome-icon
                v-if="type === 'warning'"
                class="text-yellow-500 text-3xl"
                :icon="['fas', 'exclamation-triangle']"
              />
              <font-awesome-icon
                v-else-if="type === 'question'"
                class="text-cool-gray-500 text-3xl"
                :icon="['fas', 'question-circle']"
              />
            </div>
            <div class="mt-3 text-center">
              <h3 class="text-lg leading-6 font-medium text-main">
                {{ title }}
              </h3>
              <div class="mt-2">
                <p v-if="description" class="text-sm leading-5 text-sub">
                  <span class="whitespace-pre-line">{{ description }}</span>
                </p>
                <slot name="content" />
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UiDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      wrapper: this.value,
      newValue: this.value,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value
      if (value) {
        document.documentElement.classList.add('is-clipped')
      } else {
        document.documentElement.classList.remove('is-clipped')
      }
    },
  },
}
</script>

<style lang="sass">
.ui-dialog__overlay
  backdrop-filter: blur(5px)
</style>
