const stateKeysAndDefaultValues = {
  studioTodoSelectedGroupId: 1,
  studioTodoShowOnlyIncompletedTasks: false,
  merchandiseActiveTabIndex: 0,
  todoShowOnlyIncompletedTasks: false,
  scheduleMode: 'upcoming',
  scheduleSelectedLabelId: 0,
  equipmentSearchBox: '',
  equipmentSelectedTagId: 0,
  costumeSearchBox: '',
  salesShopId: 1,
  salesRankingActiveTabIndex: 0,
  searchInput: '',
  merchandiseActiveScheduleId: null,
  merchandiseActiveScheduleExpiresAt: null,
  currentScheduleIndex: 0,
  currentScheduleIndexExpiresAt: null,
}

const getters = {}
const mutations = {}

Object.keys(stateKeysAndDefaultValues).forEach(key => {
  getters[key] = state => state[key]
  mutations[key] = (state, value) => {
    state[key] = value
  }
})

export default {
  state: stateKeysAndDefaultValues,
  getters,
  mutations,
  namespaced: true,
}
