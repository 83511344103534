import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import user from './modules/user';
import app from './modules/app';
import appTemp from './modules/appTemp';
import layout from './modules/layout';
import notification from './modules/notification';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    app,
    appTemp,
    layout,
  },
  plugins: [createPersistedState({
    key: 'tfmsAppData',
    paths: ['app'],
  })],
});
