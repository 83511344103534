<template>
  <svg
    class="w-14 inline"
    height="56"
    viewBox="0 0 126 56"
    width="126"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="m0 0h126v56h-126z"
    opacity="0"
  /><path
    d="m113.064 44.063h12.078c-.407-.337-1.624-.375-3.713-4.5-.76-1.583-7.338-19.377-8.667-22.485-.877-2.052-.767-4.069.034-5.164h-11.34c.471.6 1.223 2.307-.166 5.474-1.547 3.528-3.658 9.048-3.925 9.75a44.351 44.351 0 0 0 -11.809 3.111l13.775-18.335h-22.872v7.957a12.372 12.372 0 0 1 3.895-1.381 27.324 27.324 0 0 1 4.856-.376c.363 0 .711.006 1.037.018l-14.058 18.83v-18.52c0-2.144.4-4.9 1.78-6.528h-10.507c1.373 1.581 1.348 3.654 1.483 6.569s.394 11.882.708 16.017c-.437-.9-.857-1.693-1.251-2.354-.708-1.187-8.744-14.176-8.81-14.282-1.139-1.623-2.373-4.14-1.637-5.95h-10.655c1.269 1.77 1.583 4.319 1.583 6.528v17.229c-1.106-3.017-5.46-14.852-7.083-18.593a6.039 6.039 0 0 1 0-5.164h-11.285c.967 1.505.469 3.807-.192 5.478-1.17 2.958-2.854 7.157-3.465 8.681a73.2 73.2 0 0 0 -14.058-.673v-7.19c.327-.023 1.309-.082 2.886-.082 1.151 0 2.389.031 3.679.092a19.906 19.906 0 0 1 7.093 1.531v-7.84h-22.204s1.583 1.7 1.583 6.456v19.24c0 4.757-1.583 6.456-1.583 6.456h10.133s-1.587-1.699-1.587-6.456v-5.456c1.595-.11 3.177-.166 4.706-.166a54.649 54.649 0 0 1 6.784.4c-.244.662-1.313 3.323-2.676 6.636a9.582 9.582 0 0 1 -3.911 5.041h11.941s-2.105-1.368-.259-6.409c.936-2.554 1.363-3.768 1.477-4.063 3.51.681 9.453 3.489 11.928 6.314a10.266 10.266 0 0 1 2.419 4.157h12.391c-.338-.483-1.545-2.135-1.616-6.153 0-.022-.476-14.293-.477-14.315.973 1.819 8.347 14 8.48 14.2 1.429 2.166 2.34 4.8 1.457 6.266h15.656l.037-.075c1.609-3.284 9.165-8.037 17.5-9.683-.172.458-.678 1.724-1.967 4.533-1.518 3.307-2.135 4.171-3.854 5.224h11.69c-1.735-1.275.682-8.547 1.762-10.8 1.237-.1 2.475-.144 3.68-.144a32.608 32.608 0 0 1 5.9.55c.745 1.955 1.166 3.111 1.474 3.878 1.091 2.722 1.536 5.493-.258 6.521zm-76.544-13.114a24.811 24.811 0 0 0 -4.371-2.187c-.958-.412-1.919-.779-2.89-1.107 1.559-4.187 2.109-5.646 2.772-7.947.946 2.579.558 1.592 4.489 11.241zm68.08-4.163a64.62 64.62 0 0 0 2.4-7.068c.237.655 1.84 5.072 2.773 7.407a29.435 29.435 0 0 0 -5.173-.339z"
    fill="currentColor"
    fill-rule="evenodd"
  /></svg>
</template>

<script>
export default {
  name: 'IconFanza',
};
</script>
