import Vue from 'vue'
import axios from 'axios'
import { nanoid } from 'nanoid'
import VueLazyload from 'vue-lazyload'
import VueClipboard from 'vue-clipboard2'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowAltCircleRight,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faHistory,
  faVideo,
  faPlus,
  faMinus,
  faMinusCircle,
  faExternalLinkSquareAlt,
  faExternalLinkAlt,
  faEdit,
  faEllipsisH,
  faStore,
  faCaretSquareDown,
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faCircleNotch,
  faQuestionCircle,
  faBell,
  faSearch,
  faTrashAlt,
  faUndo,
  faClock,
  faPen,
  faGripVertical,
  faTimes,
  faQrcode,
  faSignInAlt,
  faVials,
  faCamera,
  faBox,
  faTshirt,
  faSocks,
  faCircle,
} from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import uiComponents from './components/@ui'
import iconComponents from './components/@icons'
import './assets/styles/tailwind.css'

library.add(
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowAltCircleRight,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCaretSquareDown,
  faClock,
  faVideo,
  faPlus,
  faUndo,
  faMinus,
  faMinusCircle,
  faHistory,
  faTwitter,
  faInstagram,
  faEdit,
  faEllipsisH,
  faGripVertical,
  faStore,
  faBell,
  faPen,
  faTimes,
  faHeart,
  faQrcode,
  faExternalLinkSquareAlt,
  faExternalLinkAlt,
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faCircleNotch,
  faQuestionCircle,
  faTrashAlt,
  faSignInAlt,
  faVials,
  faCamera,
  faBox,
  faTshirt,
  faSocks,
  faCircle,
  faSearch,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

uiComponents.forEach(component => {
  Vue.component(component.name, component)
})
iconComponents.forEach(component => {
  Vue.component(component.name, component)
})

Vue.use(VueLazyload)
Vue.use(VueClipboard)

const token = localStorage.getItem('authToken')
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

Vue.prototype.$deviceId = nanoid()

Vue.prototype.$moduloBox = new ModuloBox()
Vue.prototype.$moduloBox.replaceState = function replaceState() {}
Vue.prototype.$moduloBox.init()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
