const getters = {
  open: (state) => state.open,
  message: (state) => state.message,
  type: (state) => state.type,
};

const actions = {
  async set({ commit }, opts) {
    commit('setMessage', opts.message);
    commit('setType', opts.type);
    commit('setOpen', true);
    setTimeout(() => {
      commit('setOpen', false);
    }, opts.timeout ?? 1500);
  },
};

const mutations = {
  setMessage(state, message) {
    state.message = message;
  },
  setType(state, type) {
    state.type = type;
  },
  setOpen(state, value) {
    state.open = value;
  },
};

const state = {
  open: false,
  message: '',
  type: '',
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
