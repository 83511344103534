<template>
  <div>
    <apexchart
      type="radialBar"
      :options="options"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'UiProgressCircle',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    options() {
      return {
        grid: {
          padding: {
            left: 0,
            right: 0,
            top: -10,
            bottom: -20,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '50%',
            },
            track: {
              background: 'rgba(255,255,255,.2)',
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: 'rgba(255,255,255,.8)',
                fontSize: '12px',
                show: true,
                offsetY: 6,
              },
            },
          },
        },
        fill: {
          colors: ['rgba(255,255,255,.8)'],
        },
        stroke: {
          lineCap: 'round',
        },
      };
    },
    percentage() {
      return Math.floor((this.value / this.max) * 100);
    },
    series() {
      return [this.percentage];
    },
  },
};
</script>
