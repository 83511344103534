<template>
  <label class="block">
    <p
      v-if="label"
      class="text-sub text-xs ml-1 mb-1"
      v-text="label"
    />
    <div class="flex justify-between">
      <button
        class="focus:outline-none text-sub focus:text-main w-10 disabled:text-disabled"
        :disabled="min === computedValue"
        @click="decrement"
      >
        <font-awesome-icon
          class="text-md"
          :icon="['fas', 'minus']"
        />
      </button>
      <input
        v-model="computedValue"
        type="number"
        class="form-input flex-glow mx-3 border-none block w-full bg-level-1 shadow-xl focus:bg-level-3 text-main rounded-2xl px-6 transition-colors duration-100"
        :placeholder="placeholder"
      >
      <button
        class="focus:outline-none text-sub focus:text-main w-10 disabled:text-disabled"
        :disabled="max === computedValue"
        @click="increment"
      >
        <font-awesome-icon
          class="text-md"
          :icon="['fas', 'plus']"
        />
      </button>
    </div>
  </label>
</template>

<script>
export default {
  name: 'UiNumberInput',
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newValue: Number(this.value),
    };
  },
  computed: {
    computedValue: {
      get() {
        return Number(this.newValue);
      },
      set(value) {
        const hasChanged = this.newValue !== value;
        this.newValue = value;
        this.$emit('input', Number(value));
        if (hasChanged) {
          this.$emit('change', Number(value));
        }
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
  methods: {
    increment() {
      if (this.computedValue < this.max) {
        this.computedValue += 1;
      }
    },
    decrement() {
      if (this.computedValue > this.min) {
        this.computedValue -= 1;
      }
    },
  },
};
</script>
