<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 480.111 480.111"
    viewBox="0 0 480.111 480.111"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="m456.034 271.056v169c0 22.056-17.944 40-40 40h-352c-22.056 0-40-17.944-40-40v-169c0-4.418 3.582-8 8-8s8 3.582 8 8v169c0 13.234 10.766 24 24 24h87v-135c0-22.056 17.944-40 40-40h98c22.056 0 40 17.944 40 40v103c0 4.418-3.582 8-8 8s-8-3.582-8-8v-103c0-13.233-10.767-24-24-24h-98c-13.233 0-24 10.767-24 24v135h65v-126c0-4.418 3.582-8 8-8s8 3.582 8 8v126h168c13.233 0 24-10.766 24-24v-169c0-4.418 3.582-8 8-8s8 3.581 8 8zm-404-143c0 4.418 3.582 8 8 8 435.268 0 413.687.778 416.867-1.661 1.662-1.279 2.749-3.124 3.047-5.232.446-3.215 3.317 4.691-52.574-124.288-1.269-2.926-4.152-4.819-7.341-4.819h-360c-3.188 0-6.073 1.894-7.34 4.819l-52 120c-.919 2.122-.66-1.246-.66 52.813.058 36.385 29.665 65.987 66 65.987 24.979 0 46.778-13.99 57.983-34.556 25.026 45.99 90.945 46.041 116.017.031 25.052 45.973 90.942 45.983 116 0 11.197 20.548 32.998 34.525 58 34.525 36.393 0 66-29.607 66-66 0-4.418-3.582-8-8-8s-8 3.582-8 8c0 27.57-22.43 50-50 50s-50-22.43-50-50c0-4.418-3.582-8-8-8s-8 3.582-8 8c0 27.57-22.43 50-50 50s-50-22.43-50-50c0-4.418-3.582-8-8-8s-8 3.582-8 8c0 27.57-22.43 50-50 50-26.957 0-50-21.664-50-50.4 0-4.418-3.582-8-8-8s-8 3.582-8 8c0 28.493-22.899 50.4-50 50.4-27.526 0-49.957-22.436-50-50v-47.961l49.253-113.658h349.496l45.066 104h-399.814c-4.419 0-8 3.581-8 8z" /></svg>
</template>

<script>
export default {
  name: 'IconShop',
};
</script>
