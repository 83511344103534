<template>
  <button
    :type="type"
    class="px-4 py-2 w-full focus:outline-none"
    :class="classes"
    :disabled="disabled"
    @click="emitClickEventIfNotLoading"
  >
    <font-awesome-icon
      v-if="loading"
      :icon="['fas', 'circle-notch']"
      spin
    />
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    color: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    rounded: {
      type: String,
      default: 'xl',
    },
  },
  computed: {
    classes() {
      return {
        [`text-${this.size}`]: true,
        'rounded-xl': this.rounded === 'xl',
        'rounded-lg': this.rounded === 'lg',
        'bg-indigo-800': this.color === 'primary',
        'text-indigo-100': this.color === 'primary',
        'bg-error-800': this.color.startsWith('error'),
        'text-error-200': this.color.startsWith('error'),
        'bg-success-600': this.color === 'success',
        'text-success-100': this.color === 'success',
        'bg-info-600': this.color === 'info',
        'text-info-100': this.color === 'info',
        'bg-warning-600': this.color === 'warning',
        'text-warning-100': this.color === 'warning',
        'bg-level-2': this.color.startsWith('secondary'),
        'bg-opacity-50': this.color.startsWith('secondary'),
        'bg-level-1': this.color === '',
        'bg-cool-gray-900': this.color.startsWith('tertiary'),
        'text-sub': this.color === '' || this.color === 'secondary' || this.color === 'tertiary',
        'text-red-400': this.color === 'tertiary-error',
        'shadow-xl': this.color !== 'secondary' && !this.color.endsWith('-2'),
        'bg-opacity-25': this.loading || this.disabled,
        'text-opacity-25': this.disabled,
      };
    },
  },
  methods: {
    emitClickEventIfNotLoading() {
      if (this.loading || this.disabled) {
        return;
      }
      this.$emit('click');
    },
  },
};
</script>
