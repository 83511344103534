<template>
  <div class="w-full">
    <div class="login-form__logo h-36 mx-auto mb-6 bg-contain bg-no-repeat bg-center" />
    <application-updated
      v-if="applicationUpdated"
      @click="reload"
    />
    <ui-transition>
      <form
        v-if="ready"
        @submit.prevent="login"
      >
        <ui-input
          v-model="username"
          class="mb-6"
          label="ログインID"
          autocomplete="username"
        />
        <ui-input
          v-model="password"
          type="password"
          class="mb-10"
          label="パスワード"
          autocomplete="current-password"
        />
        <ui-button
          type="submit"
          color="primary"
          class="w-full"
          :loading="submitting"
        >
          ログイン
        </ui-button>
        <router-link
          tag="button"
          to="/signup"
          class="block focus:outline-none py-3 px-6 mt-4 mx-auto text-sm text-indigo-400"
          replace
        >
          <font-awesome-icon
            class="mr-1"
            :icon="['fas', 'sign-in-alt']"
          />
          参加申請
        </router-link>
      </form>
    </ui-transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ApplicationUpdated from '../components/ApplicationUpdated.vue';

export default {
  name: 'Login',
  components: {
    ApplicationUpdated,
  },
  data: () => ({
    ready: false,
    reloading: false,
    username: '',
    password: '',
    submitting: false,
  }),
  computed: {
    ...mapGetters('appTemp', ['applicationUpdated']),
  },
  created() {
    if (this.$store.getters['auth/isAuthenticated']) {
      this.$router.replace('/');
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    });
  },
  methods: {
    async login() {
      this.submitting = true;
      await this.$store.dispatch('auth/signIn', {
        username: this.username,
        password: this.password,
      });
      this.submitting = false;
      if (this.$store.getters['auth/hasError']) {
        let message;
        switch (this.$store.getters['auth/error']) {
          case 'Account is locked':
            message = 'このアカウントは現在使用できません';
            break;
          default:
            message = '認証情報に誤りがあります';
            break;
        }
        this.$store.dispatch('notification/set', {
          type: 'error',
          message,
        });
      } else {
        this.$router.replace('/');
      }
    },
    async reload() {
      this.reloading = true;
      this.$nextTick(async () => {
        if ('serviceWorker' in navigator) {
          const serviceWorkerRegistrations = await navigator.serviceWorker.getRegistrations();
          serviceWorkerRegistrations.forEach((serviceWorkerRegistration) => {
            serviceWorkerRegistration.unregister();
          });
        }
        if (caches) {
          const cacheKeys = await caches.keys();
          cacheKeys.forEach((cacheKey) => {
            caches.delete(cacheKey);
          });
        }
        window.location.reload(true);
      });
    },
  },
};
</script>

<style lang="sass">
  .login-form__logo
    background-image: url('../assets/tfms-logo.png')
</style>
