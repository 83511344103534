<template>
  <div id="app">
    <ui-header :title="$route.meta.title" />
    <ui-container v-if="ready">
      <router-view ref="currentView" />
    </ui-container>
    <ui-notification />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    ready: false,
  }),
  async created() {
    document.addEventListener(
      'applicationUpdated',
      () => {
        this.$store.commit('appTemp/applicationUpdated', true)
      },
      { once: true },
    )
    axios.defaults.timeout = 10000
    axios.interceptors.response.use(
      undefined,
      error =>
        new Promise((resolve, reject) => {
          if (axios.isCancel(error)) {
            resolve()
            return
          }

          if (!error.response) {
            this.$store.dispatch('notification/set', {
              type: 'error',
              message: `ネットワークエラーが発生しました。\n通信環境をご確認ください。\n${error}`,
              timeout: 10000,
            })
            resolve()
            return
          }

          if (error.response.status === 503) {
            this.$router.replace(`/error/maintenance`)
            resolve()
            return
          }

          if (error.response.status >= 500) {
            this.$router.replace(
              `/error/${encodeURIComponent(JSON.stringify(error.response.data))}`,
            )
            resolve()
            return
          }

          if (error.response.status === 401) {
            if (!this.$store.getters['auth/isAuthenticated']) return
            this.$store.dispatch('notification/set', {
              type: 'success',
              message: 'トークンの有効期限切れです。再ログインしてください。',
            })
            this.$store.dispatch('auth/signOut')
            this.$router.replace('/login')
            resolve()
          } else if (error.response.status === 403) {
            this.$store.dispatch('notification/set', {
              type: 'error',
              message: `${
                error.response.config.method === 'get' ? '表示する' : 'この操作を行う'
              }権限がありません。`,
              timeout: 3000,
            })
          } else {
            reject(error)
          }
        }),
    )
    if (this.$store.getters['auth/isAuthenticated']) {
      await this.$store.dispatch('user/load', null, { root: true })
    }
    this.ready = true
  },
}
</script>
<style lang="sass">
html.is-clipped
  overflow: hidden!important

body
  @apply bg-main
  height: 100vh

input::-webkit-calendar-picker-indicator
  display: none

#app
  max-width: 768px
  margin: 0 auto

.mobx-overlay
  @apply bg-black
  @apply bg-opacity-75
.mobx-top-bar
  padding-top: constant(safe-area-inset-top)
  padding-top: env(safe-area-inset-top)
.mobx-bottom-bar
  padding-bottom: constant(safe-area-inset-bottom)
  padding-bottom: env(safe-area-inset-bottom)

.draggable-ghost
  opacity: .02
.draggable-handle:hover
  cursor: pointer
</style>
