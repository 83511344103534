import Vue from 'vue';
import axios from 'axios';

const getters = {
  username: (state) => state.profile.username,
  role: (state) => state.profile.role,
  id: (state) => state.profile.id,
};

const actions = {
  async load({ commit }) {
    try {
      const resp = await axios.get('/api/users/me');
      commit('set', resp.data);
    } catch {
      commit('clear');
    }
  },
};

const mutations = {
  set(state, profile) {
    Vue.set(state, 'profile', profile);
  },
  clear(state) {
    Vue.set(state, 'profile', {});
  },
};

const state = {
  profile: {},
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
