<template>
  <div
    v-show="wrapper"
    class="ui-action fixed px-4 pb-4 inset-x-0 z-20"
  >
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      @before-enter="wrapper = true"
      @after-leave="wrapper = false"
    >
      <div
        v-if="computedValue"
        class="ui-action__overlay fixed inset-0 transition-opacity"
        @click="computedValue = false"
      >
        <div class="absolute inset-0 bg-black opacity-50" />
      </div>
    </transition>

    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-6"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-6"
    >
      <div
        v-if="computedValue"
        class="bg-level-3 rounded-xl overflow-hidden shadow-xl transform transition-all w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="px-4 pt-5 pb-4">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UiAction',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrapper: this.value,
      newValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
      if (value) {
        document.documentElement.classList.add('is-clipped');
      } else {
        document.documentElement.classList.remove('is-clipped');
      }
    },
  },
};
</script>

<style lang="sass">
.ui-action
  bottom: constant(safe-area-inset-bottom)
  bottom: env(safe-area-inset-bottom)

.ui-action__overlay
  backdrop-filter: blur(5px)
</style>
