<template>
  <div>
    <label class="inline-flex items-start">
      <input
        v-model="computedValue"
        type="checkbox"
        class="form-checkbox bg-level-3 border-indigo-400 border-opacity-50 text-indigo-600 w-5 h-5 shadow-inner"
      />
      <span
        class="ml-2"
        :class="labelClass ? labelClass : computedValue ? 'text-sub' : 'text-main'"
        >{{ label }}</span
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'UiCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newValue: this.value,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        const hasChanged = this.newValue !== value
        this.newValue = value
        this.$emit('input', value)
        if (hasChanged) {
          this.$emit('change', value)
        }
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value
    },
  },
}
</script>
