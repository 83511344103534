<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="m458.67 0h-405.33c-29.44 0.035-53.298 23.893-53.333 53.333v405.33c0.035 29.441 23.893 53.299 53.333 53.334h405.33c29.441-0.035 53.298-23.893 53.333-53.333v-405.33c-0.034-29.44-23.892-53.298-53.332-53.333zm32 458.67c0 17.673-14.327 32-32 32h-405.33c-17.673 0-32-14.327-32-32v-405.33c0-17.673 14.327-32 32-32h405.33c17.673 0 32 14.327 32 32v405.33z" /><rect
    x="245.33"
    y="42.667"
    width="21.333"
    height="426.67"
  /><path d="m320 181.33h-21.333v21.333h21.333v21.334h-21.333v21.333h21.333c0.295 6e-3 0.589 6e-3 0.884 0 11.538-0.244 20.693-9.795 20.449-21.333v-21.333c6e-3 -0.295 6e-3 -0.589 0-0.884-0.244-11.538-9.795-20.694-21.333-20.45z" /><path d="M213.333,202.667v-21.333H192c-0.295-0.006-0.589-0.006-0.884,0c-11.538,0.244-20.693,9.795-20.449,21.333V224    c-0.006,0.295-0.006,0.589,0,0.884c0.244,11.538,9.795,20.693,21.333,20.449h21.333V224H192v-21.333H213.333z" /><rect
    x="42.667"
    y="384"
    width="426.67"
    height="21.333"
  /><rect
    x="352"
    y="437.33"
    width="42.667"
    height="21.333"
  /><rect
    x="117.33"
    y="437.33"
    width="42.667"
    height="21.333"
  /></svg>
</template>

<script>
export default {
  name: 'IconCloset',
};
</script>
