<template>
  <label class="block">
    <p v-if="label" class="text-sub text-xs ml-1 mb-1">
      {{ label }}
      <span v-if="required" class="text-red-400">*</span>
    </p>
    <select
      v-model="computedValue"
      :class="
        `form-select border-none block w-full bg-level-${
          light ? 2 : 1
        } shadow-xl focus:bg-level-3 text-main rounded-xl px-4 transition-colors duration-100`
      "
      :placeholder="placeholder"
    >
      <option v-for="option in options" :key="option.value" :value="option.value">{{
        option.text
      }}</option>
      <slot />
    </select>
  </label>
</template>

<script>
export default {
  name: 'UiSelect',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        const hasChanged = this.newValue !== value
        this.newValue = value
        this.$emit('input', value)
        if (hasChanged) {
          this.$emit('change', value)
        }
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value
    },
  },
}
</script>
