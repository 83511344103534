<template>
  <ui-dialog v-model="modalOpen" title="ツイートテンプレート">
    <template #content>
      <div class="text-left">
        <ui-select
          v-model="shopId"
          class="mb-6"
          :options="shops"
          label="レーベルを選択"
          @change="generateTweet"
        />
        <div
          class="px-4 py-2 bg-level-2 bg-opacity-50 mt-4 text-main rounded-xl text-sm whitespace-pre-line break-words max-h-64 overflow-y-auto"
        >
          {{ tweet }}
        </div>
      </div>
    </template>
    <div class="grid grid-cols-2 gap-2">
      <ui-button v-clipboard:copy="url" v-clipboard:success="onCopy">
        URLのみコピー
      </ui-button>
      <ui-button v-clipboard:copy="tweet" v-clipboard:success="onCopy" color="primary">
        本文をコピー
      </ui-button>
    </div>
    <button
      class="block focus:outline-none py-3 px-6 mt-2 mx-auto text-sm text-sub"
      @click="modalOpen = false"
    >
      閉じるff
    </button>
  </ui-dialog>
</template>

<script>
import { parse, isToday, isYesterday } from 'date-fns'
import ScheduleService from '../../services/ScheduleService'

export default {
  name: 'TweetTemplateDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    scheduleId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    shops: [
      { value: 1, text: '足フェチ＆くすぐりフェチマスター' },
      { value: 2, text: '舌ベロマスター' },
      { value: 3, text: '口内・噛みつき・歯フェチマスター' },
    ],
    shopId: 1,
    url: '',
    tweet: '',
  }),
  computed: {
    modalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    scheduleId: {
      handler(value) {
        if (!value) {
          return
        }
        this.generateTweet()
      },
      immediate: true,
    },
  },
  methods: {
    async onCopy() {
      this.modalOpen = false
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: 'コピーしました！',
      })
    },
    async generateTweet() {
      const base = await ScheduleService.getTweetBase({
        shopId: this.shopId,
        scheduleId: this.scheduleId,
      })
      const shootingDate = parse(base.shooting_date, 'yyyy-MM-dd', new Date())

      let reldate
      if (isToday(shootingDate)) {
        reldate = '今日'
      } else if (isYesterday(shootingDate)) {
        reldate = '昨日'
      } else {
        reldate = '先日'
      }
      const actressNames = base.actresses.map(actress => {
        if (actress.twitter_id) {
          return `#${actress.name} ちゃん (@${actress.twitter_id} )`
        }
        return `#${actress.name} ちゃん`
      })

      this.url = `${base.url}${base.shooting_date.replace(/-/g, '')}`
      const top = `${reldate}は${actressNames.join('、')}と撮影しました🎥💕

発売をお楽しみに❣️

`

      const footer = !this.url
        ? ''
        : `会員限定動画や新作のお知らせを
イチ早く受け取りませんか？
↓
${this.url}
      `.trim()
      this.tweet = `${top}${footer}`
    },
  },
}
</script>
