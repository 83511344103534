<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m276.42 252.58h-139.38c-5.633 0-10.199 4.567-10.199 10.199v139.38c0 5.632 4.566 10.199 10.199 10.199h139.38c5.632 0 10.199-4.567 10.199-10.199v-139.38c-1e-3 -5.632-4.567-10.199-10.2-10.199zm-10.199 20.4v39.079l-39.079-39.079h39.079zm-118.98 118.98v-39.355l39.355 39.355h-39.355zm118.98 0h-50.775v1e-3l-68.203-68.204v-36.351l63.806 63.806c1.992 1.991 4.602 2.987 7.212 2.987s5.221-0.996 7.212-2.987c3.983-3.983 3.984-10.441 0-14.425l-63.805-63.806h36.624l67.929 67.929v51.05z" />
    <path d="m251.78 363.09-2.846-2.845c-3.983-3.984-10.441-3.983-14.424 1e-3 -3.983 3.983-3.982 10.441 1e-3 14.424l2.846 2.845c1.992 1.992 4.601 2.987 7.211 2.987s5.221-0.996 7.213-2.988c3.982-3.983 3.981-10.442-1e-3 -14.424z" />
    <path d="m442.79 169.33h-91.292c-5.632 0-10.199 4.567-10.199 10.199v223.6c0 5.632 4.567 10.199 10.199 10.199h91.292c5.632 0 10.199-4.567 10.199-10.199v-223.6c0-5.632-4.567-10.199-10.199-10.199zm-81.094 20.399h28.92l41.974 41.974v33.326l-70.582-70.582c-0.1-0.1-0.208-0.187-0.312-0.281v-4.437zm1e-3 203.21v-40.334l40.333 40.334h-40.333zm70.894 0h-1.712l-69.181-69.182v-36.351l70.894 70.894v34.639zm0-63.488-70.582-70.582c-0.1-0.1-0.208-0.187-0.312-0.281v-35.601l70.894 70.894v35.57zm0-126.59-13.125-13.125h13.125v13.125z" />
    <path d="m276.42 39.049h-139.38c-5.633 0-10.199 4.567-10.199 10.199v139.38c0 5.632 4.566 10.199 10.199 10.199h139.38c5.632 0 10.199-4.567 10.199-10.199v-139.38c-1e-3 -5.632-4.567-10.199-10.2-10.199zm-10.199 20.399v39.08l-39.08-39.08h39.08zm-118.98 118.98v-39.355l39.355 39.355h-39.355zm68.202-1e-3 -68.202-68.202v-36.351l104.55 104.55h-36.351zm50.776-14.424-104.55-104.55h36.624l67.93 67.93v36.624z" />
    <path d="m501.8 429.23h-11.93v-285.73c0-5.632-4.567-10.199-10.199-10.199h-155.6v-121.33c0-5.632-4.567-10.199-10.199-10.199h-214.1c-5.633 0-10.199 4.567-10.199 10.199v417.26h-9.635v-294.35c0-24.218-19.703-43.921-43.921-43.921h-21.077c-5.633 0-10.199 4.567-10.199 10.199s4.566 10.199 10.199 10.199h21.077c12.97 0 23.522 10.553 23.522 23.522v282.86c-3.971-1.091-8.145-1.686-12.457-1.686-25.963 2e-3 -47.083 21.124-47.083 47.085s21.12 47.082 47.083 47.082c25.961 0 47.083-21.121 47.083-47.082 0-4.696-0.7-9.229-1.986-13.512h323.31c-3.523 5.978-5.553 12.936-5.553 20.362 0 22.184 18.049 40.232 40.233 40.232s40.233-18.047 40.233-40.232c0-7.426-2.031-14.384-5.553-20.362h16.957c5.632 0 10.199-4.567 10.199-10.199-2e-3 -5.632-4.569-10.2-10.201-10.2zm-454.72 60.594c-14.713 0-26.684-11.971-26.684-26.683 0-14.714 11.971-26.684 26.684-26.684s26.684 11.97 26.684 26.684c0 14.712-11.971 26.683-26.684 26.683zm256.59-60.594h-193.7v-193.33h193.7v193.33zm0-285.73v71.998h-193.7v-193.33h193.7v121.33zm20.398 285.73v-275.53h145.4v275.53h-145.4zm126.09 60.594c-10.938 0-19.834-8.898-19.834-19.833 0-10.938 8.898-19.834 19.834-19.834s19.834 8.898 19.834 19.834c1e-3 10.935-8.896 19.833-19.834 19.833z" />
  </svg>
</template>

<script>
export default {
  name: 'IconCargo',
};
</script>
