const getters = {
  scrollTop: (state) => state.scrollTop,
};

const actions = {
  restorePosition({ commit, state }) {
    window.scrollTo(0, state.scrollTop);
    commit('savePosition', 0);
  },
};

const mutations = {
  savePosition(state, value) {
    state.scrollTop = value;
  },
};

const state = {
  scrollTop: 0,
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
