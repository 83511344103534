<template>
  <div :class="`grid grid-cols-${items.length} gap-0`">
    <button
      v-for="(item, i) in items"
      :key="i"
      class="text-center py-2 focus:outline-none text-sm"
      :class="computedValue === i ? tab.activeClasses : tab.normalClasses"
      type="button"
      @click="computedValue = i"
      v-text="item"
    />
    <div
      v-for="(item, i) in items"
      :key="i + items.length"
      class="tab_indicator w-full rounded-full transition-colors transition-colors duration-100"
      :class="computedValue === i ? tabIndicator.activeClasses : tabIndicator.normalClasses"
    />
  </div>
</template>

<script>
export default {
  name: 'UiTab',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newValue: this.value,
      tab: {
        activeClasses: 'text-indigo-400',
        normalClasses: 'text-sub',
      },
      tabIndicator: {
        activeClasses: 'tab_indicator--active bg-indigo-500',
        normalClasses: 'bg-transparent',
      },
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
};
</script>

<style lang="sass">
.tab_indicator
  height: .125rem
  &--active
    box-shadow: 0 0 8px theme('colors.indigo.400')
</style>
