<template>
  <div
    class="bg-yellow-500 text-main shadow-xl rounded-2xl px-3 py-2 flex items-center justify-between mb-4"
  >
    <div class="flex-grow text-xs font-bold">
      <font-awesome-icon
        :icon="['fas', 'bell']"
        class="mx-1"
      />
      アプリの更新があります！
    </div>
    <div class="flex-shrink-0">
      <button
        type="button"
        class="px-3 py-1 w-full focus:outline-none bg-white text-yellow-500 shadow-xl rounded-2xl text-xs font-bold"
        @click="$emit('click')"
      >
        更新する
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationUpdated',
};
</script>
