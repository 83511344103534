<template>
  <div>
    <p class="text-xs text-sub px-4 py-2">
      アプリ一覧
    </p>

    <router-link
      v-if="canAccessAsset"
      to="/todo"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-checklist-prompt class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4">
        チェックリスト
      </div>
    </router-link>

    <router-link
      v-if="canAccessAsset"
      to="/documents"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-checklist-prompt class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4">
        マニュアル
      </div>
    </router-link>

    <router-link
      v-if="canAccessAsset"
      to="/schedules"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-calender-with-camera class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4">
        撮影一覧
      </div>
    </router-link>

    <router-link
      v-if="canAccessAsset"
      to="/merchandise_todo/default"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub flex-shrink-0">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-test-tube class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4 flex-grow">
        物販
      </div>
    </router-link>

    <router-link
      v-if="canAccessAsset"
      to="/order"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub flex-shrink-0">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-cargo class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4 flex-grow">
        発注
      </div>
      <div class="flex-shrink">
        <div
          v-if="orderCount > 0"
          class="bg-red-500 text-red-50 rounded-full font-number py-0.5 px-3 text-xs font-bold"
        >
          {{ orderCount }}
        </div>
      </div>
    </router-link>

    <router-link
      v-if="canAccessAsset"
      to="/equipments"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-sd-card class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4">
        備品管理
      </div>
    </router-link>

    <router-link
      v-if="canAccessAsset"
      to="/costumes"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-underwear class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4">
        衣装管理
      </div>
    </router-link>

    <router-link
      v-if="canAccessSales"
      to="/sales"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-chart class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4">
        売上分析
      </div>
    </router-link>

    <router-link
      v-if="canAccessAsset"
      to="/properties"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-cog class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4">
        マスタ編集
      </div>
    </router-link>

    <router-link
      to="/studio_todo"
      class="rounded-2xl py-2 px-4 bg-level-1 active:bg-level-3 shadow-xl flex items-center mb-2"
    >
      <div class="text-sub flex-shrink-0">
        <div class="w-8 h-8 rounded-full bg-level-3">
          <icon-house-cleaning class="w-8 h-8 p-2 text-purple-200 text-center" />
        </div>
      </div>
      <div class="text-purple-100 text-opacity-75 text-sm ml-4 flex-grow">
        スタジオ片付け（廃止予定）
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderService from '../../services/OrderService'

export default {
  name: 'HomeMenu',
  data() {
    return {
      orderCount: 0,
    }
  },
  computed: {
    ...mapGetters('user', ['role']),
    canAccessSales() {
      return (
        this.role === 'admin' ||
        this.role === 'creator_tic_plus' ||
        this.role === 'creator_ton_plus' ||
        this.role === 'creator_bite_plus'
      )
    },
    canAccessAsset() {
      return this.role !== 'assistant2'
    },
  },
  async created() {
    this.orderCount = (await OrderService.getOrders()).length
  },
}
</script>
