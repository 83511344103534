<template>
  <span class="ui-dialog__button flex w-full rounded-xl shadow-sm sm:w-auto">
    <button
      type="button"
      class="inline-flex justify-center w-full rounded-xl border border-transparent px-4 py-2 text-base leading-6 font-medium focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
      :class="coloringClass"
      :loading="loading"
      @click="emitClickEventIfNotLoading"
    >
      <font-awesome-icon
        v-if="loading"
        :icon="['fas', 'circle-notch']"
        spin
      />
      <slot v-else />
    </button>
  </span>
</template>

<script>
export default {
  name: 'UiDialogButton',
  props: {
    color: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    coloringClass() {
      switch (this.color) {
        case 'error':
          return 'shadow-xl bg-red-800 text-red-200';
        case 'primary':
          return 'shadow-xl bg-indigo-800 text-indigo-200';
        default:
          return 'bg-level-2 bg-opacity-50 text-sub';
      }
    },
  },
  methods: {
    emitClickEventIfNotLoading() {
      if (this.loading) {
        return;
      }
      this.$emit('click');
    },
  },
};
</script>

<style lang="sass">
.ui-dialog__button
  &:not(:first-child)
    @apply mt-3
    @screen sm
      @apply mt-0
  &:not(:last-child)
    @apply ml-0
    @screen sm
      @apply ml-3
</style>
