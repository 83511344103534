<template>
  <div class="bg-upcoming-suggestion shadow-xl rounded-2xl text-main">
    <div class="pt-3 pb-2 px-4 bg-white bg-opacity-75 rounded-t-2xl flex items-center shadow-xl">
      <img class="w-12 h-12 rounded-xl shadow-xl mr-3 object-cover" :src="preferThumbnail" />
      <div>
        <p class="text-xxs text-black text-opacity-75">
          次の撮影日は{{ shootingDateText }}です。準備しましょう！
        </p>
        <p class="text-xl font-extrabold text-black text-opacity-75">
          {{ schedule.title }}
        </p>
      </div>
    </div>
    <div class="bg-cool-gray-800 bg-opacity-90 pb-2 rounded-b-2xl">
      <h3 class="bg-cool-gray-900 bg-opacity-50 py-1 px-4 shadow-2xl text-opacity-75 text-xxs mb-2">
        衣装を発注登録しましょう！
      </h3>
      <div class="grid grid-cols-1 gap-2 px-4">
        <div
          v-for="actress in schedule.actresses"
          :key="actress.id"
          class="flex items-center justify-between"
        >
          <div>
            <h4 class="text-xs">
              {{ actress.name }}
            </h4>
            <p class="text-xs flex items-center">
              <span class="font-number mr-2"
                ><icon-bra class="mr-1 inline -mt-0.5" style="height: 14.3px;" />{{
                  actress.bra_size ? actress.bra_size.name : '?'
                }}</span
              >
              <span class="font-number mr-2"
                ><font-awesome-icon :icon="['fas', 'tshirt']" class="mr-1" />{{
                  actress.clothing_size ? actress.clothing_size.name : '?'
                }}</span
              >
              <span class="font-number mr-2"
                ><font-awesome-icon :icon="['fas', 'socks']" class="mr-1" />{{
                  actress.shoes_size ? actress.shoes_size.name : '?'
                }}</span
              >
              <button
                class="text-xxs rounded-full bg-level-2 text-blue-300 bg-opacity-50 flex items-center justify-center px-2 py-0.5 focus:outline-none"
                @click="showActressModal(actress.id)"
              >
                <font-awesome-icon :icon="['fas', 'info-circle']" class="mr-1" />他
              </button>
            </p>
          </div>
          <router-link
            tag="button"
            type="button"
            :to="`/order?category=merchandise&scheduleId=${schedule.id}&actressId=${actress.id}`"
            class="focus:outline-none rounded-full px-4 py-2 text-xs bg-level-2 shadow-xl tracking-tight text-main font-bold"
          >
            発注登録
          </router-link>
        </div>
      </div>
      <h3
        class="bg-cool-gray-900 bg-opacity-50 py-1 px-4 shadow-2xl text-opacity-75 text-xxs mt-2 mb-2"
      >
        制作タスク
      </h3>
      <div class="flex items-center justify-between px-4">
        <span class="text-sm text-opacity-50 text-white">準備中</span>
        <!--        <router-link-->
        <!--          :to="`/preproduction/${schedule.id}`"-->
        <!--          class="flex-shrink-0 focus:outline-none rounded-full px-4 py-2 text-xxs bg-level-3 shadow-xl tracking-tight text-main font-bold"-->
        <!--        >-->
        <!--          準備中-->
        <!--        </router-link>-->
      </div>
    </div>
    <actress-modal v-model="actressModalIsOpen" :actress-id="selectedActressId" />
  </div>
</template>
<script>
import { format, isTomorrow, parse } from 'date-fns'
import ActressModal from '../actress/ActressModal.vue'

export default {
  name: 'TodaySuggestion',
  components: {
    ActressModal,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actressModalIsOpen: false,
      selectedActressId: null,
    }
  },
  computed: {
    preferThumbnail() {
      if (this.schedule.thumbnail) {
        return `/uploads/schedules/${this.schedule.thumbnail}`
      }
      return `/uploads/actresses/${this.schedule.actresses[0].thumbnail}`
    },
    shootingDate() {
      return parse(this.schedule.shooting_date, 'yyyy-MM-dd', new Date())
    },
    shootingDateText() {
      if (isTomorrow(this.shootingDate)) {
        return '明日'
      }
      return format(this.shootingDate, 'M/d')
    },
  },
  methods: {
    showActressModal(actressId) {
      this.selectedActressId = actressId
      this.actressModalIsOpen = true
    },
    navigateToMerchandise(tabIndex) {
      this.$store.commit('app/merchandiseActiveTabIndex', tabIndex)
      this.$router.push(`/merchandise_todo/${this.schedule.id}`)
    },
  },
}
</script>
<style>
.bg-upcoming-suggestion {
  background: linear-gradient(90deg, #0700b8 0%, #00ff88 100%);
}
</style>
