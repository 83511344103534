<template>
  <div>
    <label class="inline-flex items-center">
      <input
        v-model="computedValue"
        type="radio"
        :name="name"
        :value="nativeValue"
        class="form-radio bg-level-3 border-none text-indigo-600 w-5 h-5 shadow-inner"
      >
      <span class="ml-2 text-sub">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'UiRadio',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    nativeValue: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
};
</script>
