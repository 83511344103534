import axios from 'axios';

const getters = {
  token: (state) => state.token,
  isAuthenticated: (state) => !!state.token,
  hasError: (state) => !!state.error,
  error: (state) => state.error,
  invitationRealName: (state) => state.invitationRealName,
};

const actions = {
  async signIn({ commit, dispatch }, auth) {
    commit('start');
    const resp = await axios.post('/api/login', auth);
    if (resp.data.error) {
      commit('signInFailure', resp.data.error);
      return;
    }
    localStorage.setItem('authToken', resp.data.token);
    axios.defaults.headers.common.Authorization = `Bearer ${resp.data.token}`;
    commit('signInSuccess', resp.data.token);
    await dispatch('user/load', null, { root: true });
  },
  signOut({ commit }) {
    localStorage.removeItem('authToken');
    delete axios.defaults.headers.common.Authorization;
    commit('clear');
    commit('user/clear', null, { root: true });
  },
  async verifyInvitationCode({ commit }, invitationCode) {
    const resp = await axios.post('/api/verify-invitation-code', { invitation_code: invitationCode });
    if (resp.data.error) {
      commit('invitationRejected', resp.data.error);
      return;
    }
    commit('invitationAccepted', {
      invitationVerifyCode: resp.data.invitation_verify_code,
      invitationId: resp.data.invitation_id,
      invitationRealName: resp.data.real_name,
    });
  },
  async signUp({ commit, state, dispatch }, data) {
    const resp = await axios.post('/api/signup', {
      invitation_id: state.invitationId,
      invitation_verify_code: state.invitationVerifyCode,
      display_name: data.displayName,
      username: data.username,
      password: data.password,
    });
    if (resp.data.error) {
      commit('signUpFailure', resp.data.error);
      return;
    }
    commit('clearInvitationInfo');
    await dispatch('signIn', {
      username: data.username,
      password: data.password,
    });
  },
};

const mutations = {
  start(state) {
    state.error = '';
  },
  signInSuccess(state, token) {
    state.token = token;
    state.error = '';
  },
  signInFailure(state, error) {
    state.error = error;
  },
  invitationAccepted(state, { invitationVerifyCode, invitationId, invitationRealName }) {
    state.invitationVerifyCode = invitationVerifyCode;
    state.invitationId = invitationId;
    state.invitationRealName = invitationRealName;
    state.error = '';
  },
  invitationRejected(state, error) {
    state.error = error;
  },
  signUpSuccess(state, token) {
    state.token = token;
    state.error = '';
  },
  signUpFailure(state, error) {
    state.error = error;
  },
  clear(state) {
    state.token = '';
    state.error = '';
  },
  clearInvitationInfo(state) {
    state.invitationVerifyCode = '';
    state.invitationId = 0;
    state.invitationRealName = '';
  },
};

const state = {
  token: localStorage.getItem('authToken') || '',
  error: '',
  invitationVerifyCode: '',
  invitationId: 0,
  invitationRealName: '',
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
