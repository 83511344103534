<template>
  <div>
    <ui-transition>
      <div v-if="show">
        <div class="flex justify-between items-center mb-4">
          <div>
            <p class="text-xs tracking-widest text-sub font-number">
              {{ todayHeader }}
            </p>
            <h2 class="text-main text-2xl font-extrabold font-title">
              Hi, {{ currentUserNickName }}!
            </h2>
          </div>
          <button
            type="button"
            class="rounded-full text-lg shadow-xl text-white bg-level-1 w-10 h-10 focus:outline-none"
            @click="searchModalOpen = true"
          >
            <font-awesome-icon :icon="['fas', 'search']" />
          </button>
        </div>
        <application-updated v-if="applicationUpdated" @click="reload" />
        <template v-if="canAccessAsset">
          <div v-if="nextSchedules.length === 0" class="my-2 text-sub text-sm">
            直近の撮影予定はありません
          </div>
          <div v-else>
            <today-suggestion
              v-if="nextSchedules[currentScheduleIndex].shooting_date === today"
              :schedule="nextSchedules[currentScheduleIndex]"
            />
            <upcoming-suggestion v-else :schedule="nextSchedules[currentScheduleIndex]" />
            <div
              class="flex items-center justify-center text-sub divide-x divide-opacity-25 divide-cool-gray-400 my-2 text-xxs"
            >
              <button
                type="button"
                class="rounded-l-full px-3 py-1 bg-level-3 shadow-2xl focus:outline-none"
                :class="{ 'opacity-25': currentScheduleIndex === 0 }"
                :disabled="currentScheduleIndex === 0"
                @click="currentScheduleIndex -= 1"
              >
                <font-awesome-icon :icon="['fas', 'chevron-left']" class="mr-1" />前の撮影
              </button>
              <button
                type="button"
                class="rounded-r-full px-3 py-1 bg-level-3 shadow-2xl focus:outline-none"
                :class="{ 'opacity-25': currentScheduleIndex === nextSchedules.length - 1 }"
                :disabled="currentScheduleIndex === nextSchedules.length - 1"
                @click="currentScheduleIndex += 1"
              >
                次の撮影<font-awesome-icon :icon="['fas', 'chevron-right']" class="ml-1" />
              </button>
            </div>
          </div>
        </template>
        <home-menu />

        <div>
          <button class="block mx-auto text-sub text-sm focus:outline-none mt-8" @click="logout">
            ログアウト
          </button>
          <button class="block mx-auto text-sub text-sm focus:outline-none mt-4" @click="reload">
            <template v-if="reloading">
              更新中
            </template>
            <template v-else>
              アプリを更新
            </template>
          </button>
        </div>
      </div>
    </ui-transition>
    <search-modal v-model="searchModalOpen" />
  </div>
</template>

<script>
import { endOfDay, format } from 'date-fns'
import { mapGetters } from 'vuex'
import ApplicationUpdated from '../components/ApplicationUpdated.vue'
import TodaySuggestion from '../components/home/TodaySuggestion.vue'
import UpcomingSuggestion from '../components/home/UpcomingSuggestion.vue'
import HomeMenu from '../components/home/HomeMenu.vue'
import ScheduleService from '../services/ScheduleService'
import SearchModal from '@/components/search/SearchModal'

export default {
  components: {
    SearchModal,
    ApplicationUpdated,
    TodaySuggestion,
    UpcomingSuggestion,
    HomeMenu,
  },
  data: () => ({
    show: false,
    reloading: false,
    nextSchedules: null,
    today: format(new Date(), 'yyyy-MM-dd'),
    searchModalOpen: false,
  }),
  computed: {
    ...mapGetters('appTemp', ['applicationUpdated']),
    ...mapGetters('user', ['role']),
    todayHeader() {
      return format(new Date(), 'yyyy/M/d (E)')
    },
    currentUserNickName() {
      return this.$store.getters['user/username']
    },
    currentScheduleIndex: {
      get() {
        return this.$store.getters['app/currentScheduleIndex']
      },
      set(value) {
        this.$store.commit('app/currentScheduleIndex', value)
      },
    },
    canAccessAsset() {
      return (
        this.role === 'admin' ||
        this.role === 'sub_admin' ||
        this.role === 'creator_tic_plus' ||
        this.role === 'creator_ton_plus' ||
        this.role === 'creator_bite_plus' ||
        this.role === 'creator' ||
        this.role === 'assistant'
      )
    },
  },
  async created() {
    this.nextSchedules = await ScheduleService.getUpcoming()
    if (
      this.$store.getters['app/currentScheduleIndexExpiresAt'] &&
      Date.parse(this.$store.getters['app/currentScheduleIndexExpiresAt']) <= new Date().getTime()
    ) {
      this.currentScheduleIndex = 0
    }
    this.show = true
    this.$store.commit('app/currentScheduleIndexExpiresAt', endOfDay(new Date()).toISOString())
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/signOut')
      this.$router.replace('/login')
    },
    async reload() {
      this.reloading = true
      localStorage.removeItem('tfmsAppData')
      this.$nextTick(async () => {
        if ('serviceWorker' in navigator) {
          const serviceWorkerRegistrations = await navigator.serviceWorker.getRegistrations()
          serviceWorkerRegistrations.forEach(serviceWorkerRegistration => {
            serviceWorkerRegistration.unregister()
          })
        }
        if (caches) {
          const cacheKeys = await caches.keys()
          cacheKeys.forEach(cacheKey => {
            caches.delete(cacheKey)
          })
        }
        window.location.reload(true)
      })
    },
  },
}
</script>

<style lang="sass">
.home__logo
  background-image: url('../assets/tfms-logo.png')
</style>
