<template>
  <svg
    fill="currentColor"
    enable-background="new 0 0 472.029 472.029"
    version="1.1"
    viewBox="0 0 472.03 472.03"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m64.004 264c-4.418 0-8 3.582-8 8v32c0 4.418 3.582 8 8 8s8-3.582 8-8v-32c0-4.418-3.582-8-8-8z" />
    <path d="m96.004 280c-4.418 0-8 3.582-8 8v32c0 4.418 3.582 8 8 8s8-3.582 8-8v-32c0-4.418-3.582-8-8-8z" />
    <path d="m387.8 322.94c-3.729-2.37-8.673-1.267-11.043 2.462-2.274 3.579-1.361 8.311 2.083 10.786 21.99 14.806 27.813 44.635 13.008 66.624-14.806 21.99-44.635 27.814-66.624 13.008-21.99-14.806-27.814-44.635-13.008-66.624 8.915-13.241 23.83-21.184 39.792-21.192 4.418 0 8-3.582 8-8s-3.582-8-8-8c-35.346 7e-3 -63.995 28.666-63.988 64.012s28.666 63.995 64.012 63.988 63.995-28.666 63.988-64.012c-4e-3 -21.275-10.58-41.158-28.22-53.052z" />
    <path d="m444.24 349.43c-7.751-26.851-26.823-48.989-52.231-60.628v-176.8c1e-3 -2.829-1.493-5.448-3.928-6.888l-176-104c-2.512-1.485-5.633-1.485-8.144 0l-176 104c-0.168 0.096-0.288 0.24-0.448 0.352s-0.312 0.24-0.472 0.368c-0.695 0.547-1.292 1.207-1.768 1.952 0 0.056-0.096 0.088-0.128 0.144v0.056c-0.451 0.813-0.757 1.698-0.904 2.616 0 0.24-0.072 0.464-0.096 0.704s-0.112 0.456-0.112 0.696v208c-1e-3 2.829 1.493 5.448 3.928 6.888l176 104c0.211 0.102 0.427 0.193 0.648 0.272 0.218 0.109 0.443 0.205 0.672 0.288 1.761 0.736 3.743 0.736 5.504 0 0.232-0.083 0.459-0.179 0.68-0.288 0.208-0.096 0.432-0.152 0.64-0.272l47.768-28.232c14.699 50.918 67.891 80.28 118.81 65.581 50.918-14.698 80.28-67.891 65.581-118.81zm-236.23-332.14 41.6 24.584-61.68 36.448c-3.806 2.249-5.069 7.158-2.82 10.964s7.158 5.069 10.964 2.82l69.272-40.904 41.6 24.584-160.27 94.68-41.6-24.584 67.016-39.6c3.806-2.249 5.069-7.158 2.82-10.964s-7.158-5.069-10.964-2.82l-74.6 44.088-41.608-24.584 160.27-94.712zm-69.336 167.04v53.416l-41.328-24.424v-53.416l41.328 24.424zm120.5 166.94c-2.128 8.07-3.191 16.384-3.161 24.73 2e-3 3.348 0.178 6.694 0.528 10.024l-40.528 23.96v-25.984c0-4.418-3.582-8-8-8s-8 3.582-8 8v25.984l-160-94.544v-189.42l41.336 24.424v67.432c-3e-3 2.832 1.49 5.455 3.928 6.896l57.328 33.88c1.234 0.726 2.64 1.11 4.072 1.112 4.418 0 8-3.582 8-8v-57.992l45.336 26.792v131.43c0 4.418 3.582 8 8 8s8-3.582 8-8v-131.43l144.99-85.68c3.804-2.249 5.065-7.156 2.816-10.96s-7.156-5.065-10.96-2.816l-144.85 85.6-45.6-26.952 160.27-94.712 53.328 31.512v166.64c-51.061-13.467-103.37 17.008-116.84 68.07zm92.839 104.73c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80c-0.048 44.163-35.837 79.951-80 80z" />
    <path d="m381.46 354.35c-3.1-2.995-8.016-2.995-11.116 0l-30.344 30.344-6.344-6.344c-3.178-3.07-8.242-2.982-11.312 0.196-2.995 3.1-2.995 8.016 0 11.116l12 12c3.124 3.123 8.188 3.123 11.312 0l36-36c3.07-3.178 2.982-8.243-0.196-11.312z" />
  </svg>
</template>

<script>
export default {
  name: 'IconBoxCheck',
};
</script>
